import { expandBorderStyles } from 'baseui/styles';

export const BaseWebHelpers = {
  margin: (topOrMain: string | number, right?: string | number, bottom?: string | number, left?: string | number) => ({
    /* Note that fallbacks must check to see if original value is a literal zero, otherwise may default inappropriately */
    marginTop: topOrMain,
    marginRight: right || right === 0 ? right : topOrMain,
    marginBottom: bottom || bottom === 0 ? bottom : topOrMain,
    marginLeft: left || left === 0 ? left : topOrMain,
  }),
  padding: (topOrMain: string, right?: string, bottom?: string, left?: string) => ({
    paddingTop: topOrMain,
    paddingRight: right || topOrMain,
    paddingBottom: bottom || topOrMain,
    paddingLeft: left || topOrMain,
  }),
  borderRadius: (topLeftOrMain: string, topRight?: string, bottomLeft?: string, bottomRight?: string) => ({
    borderTopLeftRadius: topLeftOrMain,
    borderTopRightRadius: topRight || topLeftOrMain,
    borderBottomLeftRadius: bottomLeft || topLeftOrMain,
    borderBottomRightRadius: bottomRight || topLeftOrMain,
  }),
  expandBorderStyles: expandBorderStyles,
};
