import Link from 'next/link';
import { ReactNode, isValidElement, cloneElement } from 'react';

function isClassComponent(component: any) {
  return typeof component === 'function' && !!component.prototype?.isReactComponent;
}

function isFunctionComponent(component: any) {
  return typeof component === 'function' && String(component).includes('return React.createElement');
}

function isReactComponent(component: any) {
  return isClassComponent(component) || isFunctionComponent(component);
}

function isElement(element: any) {
  return isValidElement(element);
}

function isDOMTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'string';
}

function isCompositeTypeElement(element: any) {
  return isElement(element) && typeof element.type === 'function';
}

export const renderChildrenHelpers = (children: any, props = {}) => {
  if (!children) {
    return null;
  }
  if (typeof children === 'function') {
    return children(isReactComponent(children) ? undefined : props);
  }
  if (typeof children === 'string') {
    return <>{children}</>;
  }
  if (children instanceof Array) {
    return children.map((child, index) =>
      cloneElement(
        child,
        isReactComponent(children)
          ? undefined
          : {
              key: index,
              ...props,
            }
      )
    );
  }
  return cloneElement(children, isReactComponent(children) ? undefined : props);
};

export const componentOrFallback = (cmp: any, fallback: ReactNode) => {
  if (isElement(cmp)) {
    return cmp;
  } else {
    return fallback;
  }
};

export const optionalLinkWrap = (
  content: any,
  link?: string,
  onClick?: () => any,
  style?: any,
  prefetch = true,
  target: string = null
) => {
  if (link || onClick) {
    return (
      <Link
        {...(onClick ? { onClick: onClick } : {})}
        href={link ?? ''}
        {...(style ? { style: style } : {})}
        {...(prefetch
          ? {}
          : {
              /* Only add the prefetch condition if false, see https://nextjs.org/docs/messages/prefetch-true-deprecated */
              prefetch: prefetch,
            })}
        {...(target ? { target: target } : {})}
      >
        {content}
      </Link>
    );
  } else {
    return content;
  }
};
