import { useStyletron } from 'baseui';
import { useCallback, useEffect, useMemo, useState } from 'react';

const DEFAULT_BREAKPOINT = 'xlarge';

// Handler to call on window resize
function handleResize(sortedBreakpoints: [string, number][]): string {
  if (typeof document === 'undefined') {
    return DEFAULT_BREAKPOINT;
  } else {
    // If the document is available get the root HTML element
    const htmlElement = document.getElementsByTagName('html')[0];

    // Hunt for the breakpoint to use, basing it on window.innerWidth meeting the breakpoint
    // Return the new breakpoint name
    return sortedBreakpoints.reduce((acc, [name, width]) => {
      if (htmlElement.clientWidth >= width) {
        return name;
      } else {
        return acc;
      }
    }, sortedBreakpoints[0][0]);
  }
}

const BREAKPOINT_CHANGE_DEBOUNCE_MS = 100;

export function useBreakpoint() {
  // Initialize state with consistent so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [breakpoint, setBreakpoint] = useState(DEFAULT_BREAKPOINT);
  const [, /*css*/ theme] = useStyletron();

  useEffect(() => {
    console.debug(useBreakpoint.name, ': New breakpoint set:', breakpoint);
  }, [breakpoint]);

  // Create a sorted list of the breakpoints so this doesn't need to be done every time
  // Must still be done in a location with access to the current theme, can't pull out of custom hook scope
  const sortedBreakpoints: [string, number][] = useMemo(
    () => Object.entries(theme.breakpoints).sort((a, b) => (a[1] >= b[1] ? 1 : -1)),
    [theme.breakpoints]
  );

  // Set up debounce to not trigger breakpoint changes constantly as the viewport expands/contracts
  const [timerID, setTimerID] = useState(null);
  const onChangeDebounced = useCallback(() => {
    clearTimeout(timerID);
    setTimerID(
      setTimeout(() => {
        setBreakpoint(handleResize(sortedBreakpoints));
      }, BREAKPOINT_CHANGE_DEBOUNCE_MS)
    );
  }, [timerID, sortedBreakpoints]);

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', onChangeDebounced);

    // Call handler right away so state gets updated with initial window size
    setBreakpoint(handleResize(sortedBreakpoints));

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', onChangeDebounced);
  }, [onChangeDebounced, sortedBreakpoints]);

  return breakpoint;
}
