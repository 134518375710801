import { ActionType } from '../../models';

export enum SearchActionTypes {
  TOGGLE_FILTERS = '[Search] Toggle filters',
  TOGGLE_MAP = '[Search] Toggle map',
  SET_LAST_SEARCH_URL = '[Search] Set the last search URL',
  SET_LAST_SEARCH_STATEFUL_CONFIG = '[Search] Set the last search stateful configuration params',
  SET_SELECTED_FILTER_ATTRIBUTES = '[Search] Set the list of filter attributes to be shown and used',
}

export const toggleSearchFilters = (payload: boolean): ActionType => ({
  type: SearchActionTypes.TOGGLE_FILTERS,
  payload,
});

export const toggleSearchMap = (payload: boolean): ActionType => ({
  type: SearchActionTypes.TOGGLE_MAP,
  payload,
});

export const setLastSearchURL = (payload: string): ActionType => ({
  type: SearchActionTypes.SET_LAST_SEARCH_URL,
  payload,
});

export const setLastSearchStatefulConfig = (payload: Record<string, any>): ActionType => ({
  type: SearchActionTypes.SET_LAST_SEARCH_STATEFUL_CONFIG,
  payload,
});
