import { UserRole } from '@benefeature/shared-types';
import { DASHBOARD_TYPES, PERMISSIONS, SUBSCRIPTIONS } from '@benefeature/shared-common';
import { Session } from 'next-auth';
import { DASHBOARDS_CLAIM_KEY } from '../claimsAndPermissions';

export function isAuthenticated(session: Session | null | undefined) {
  return session?.user?.id;
}

export function isAdmin(session: Session | null | undefined) {
  return meetsSomeRole([{ hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] }], session);
}

export function enterpriseOrAdmin(session: Session | null | undefined) {
  return meetsSomeRole(
    [{ hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] }, { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] }],
    session
  );
}

export function subscribedOrAdmin(session: Session | null | undefined) {
  return meetsSomeRole(
    [
      { hasSubscriptions: [SUBSCRIPTIONS.PRO] },
      { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
      { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
    ],
    session
  );
}

export function userHasPermission(permission: PERMISSIONS, session: Session | null | undefined): boolean {
  return _hasPermission(permission, session?.user?.claims);
}

function _hasPermission(permission: PERMISSIONS, claims: Record<string, unknown>): boolean {
  // Map all permissions defined to a check against auth claims
  switch (permission) {
    case PERMISSIONS.SYSTEM_ADMIN:
      return !!claims?.isAdmin;

    case PERMISSIONS.TEAM_MANAGER:
      return !!claims?.isTeamManager;

    case PERMISSIONS.IS_AUTHENTICATED:
      return Object.keys(claims ?? {}).length > 0;
  }

  // Default to false
  return false;
}

export function userHasSubscription(
  subscription: SUBSCRIPTIONS,
  session: Session | null | undefined,
  checkWithoutTiering?: boolean
): boolean {
  return _hasSubscription(subscription, session?.user?.claims, checkWithoutTiering);
}

function _hasSubscription(
  subscription: SUBSCRIPTIONS,
  sessionClaims: Record<string, unknown>,
  checkWithoutTiering?: boolean
): boolean {
  // Map all subscriptions defined to a check against user claims
  switch (subscription) {
    case SUBSCRIPTIONS.PRO:
      // Any Pro subscription check should also pass for Enterprise subscriptions if the check is to be done without tiering
      return (checkWithoutTiering ? ['Pro'] : ['Pro', 'Enterprise']).includes(
        sessionClaims?.currentSubscription as string
      );

    case SUBSCRIPTIONS.ENTERPRISE:
      return sessionClaims?.currentSubscription === 'Enterprise';
  }

  // Default to false
  return false;
}

function _hasClaim(claimKey: string, sessionClaims: Record<string, unknown>): boolean {
  // Check if the provided claim key string is present on the session user claims
  return sessionClaims[claimKey] as boolean;
}

export function userHasClaim(claimKey: string, session: Session | null | undefined): boolean {
  return _hasClaim(claimKey, session?.user?.claims);
}

function _hasDashboard(dashboard: DASHBOARD_TYPES, sessionClaims: Record<string, unknown>): boolean {
  // Check the provided dashboard string against user claims
  return (sessionClaims?.[DASHBOARDS_CLAIM_KEY] as string[])?.includes(dashboard);
}

export function meetsSomeRole(rolesToCheck: UserRole[], session: Session | null | undefined): boolean {
  // Verify some roles were provided
  // If not, return true as no stipulations were provided
  if (!rolesToCheck || !rolesToCheck.length) {
    return true;
  }

  // Return false if there's no session or there's no user
  if (!session || !session?.user) {
    return false;
  }

  // Return false if the user isn't active
  if (!session?.user?.is_active) {
    return false;
  }

  // Verify some claims were provided to validate against
  // todo someday refactor this out since all users have some sort of claims, directly validate properties instead
  //     note that several claims should have supplemental information pulled anyway, such as subscriptions needing to be active and current
  if (session?.user?.claims) {
    // Check whether some roles are met by this auth's claims
    return rolesToCheck?.some((role) => {
      // Run the checks for this role
      return (
        (!role.hasPermissions ||
          !role.hasPermissions.length ||
          role.hasPermissions.every((permission) => _hasPermission(permission, session?.user?.claims))) &&
        (!role.hasSubscriptions ||
          !role.hasSubscriptions.length ||
          role.hasSubscriptions.every((subscription) => _hasSubscription(subscription, session?.user?.claims))) &&
        (!role.hasDashboards ||
          !role.hasDashboards.length ||
          role.hasDashboards.every((dashboard) => _hasDashboard(dashboard, session?.user?.claims))) &&
        (!role.hasClaims ||
          !role.hasClaims.length ||
          role.hasClaims.every((claim) => _hasClaim(claim, session?.user?.claims)))
      );
    });
  }

  // Fall back to false
  return false;
}
