import { ActionType } from '../../models';
import { SearchActionTypes } from './search.actions';

export interface SearchReducerState {
  filtersActive: boolean;
  mapActive: boolean;
  lastSearchURL: string;
  lastSearchStatefulConfig: Record<string, any>;
  selectedFilterAttributes: string[];
}

const initialState: SearchReducerState = {
  filtersActive: true,
  mapActive: false,
  lastSearchURL: null,
  lastSearchStatefulConfig: {},
  selectedFilterAttributes: [],
};

const searchReducer = (state = initialState, action: ActionType): SearchReducerState => {
  switch (action.type) {
    case SearchActionTypes.TOGGLE_FILTERS:
      return {
        ...state,
        filtersActive: action.payload,
      };

    case SearchActionTypes.TOGGLE_MAP:
      return {
        ...state,
        mapActive: action.payload,
      };

    case SearchActionTypes.SET_LAST_SEARCH_URL:
      return {
        ...state,
        lastSearchURL: action.payload,
      };

    case SearchActionTypes.SET_LAST_SEARCH_STATEFUL_CONFIG:
      return {
        ...state,
        lastSearchStatefulConfig: action.payload,
      };

    case SearchActionTypes.SET_SELECTED_FILTER_ATTRIBUTES:
      return {
        ...state,
        selectedFilterAttributes: action.payload,
      };

    default:
      return state;
  }
};

export default searchReducer;
