import { Block } from 'baseui/block';
import { DisplayMedium } from 'baseui/typography';
import { useDebugAttrs } from '../hooks';
import Image from 'next/image';

export type BePageOverlayMessageProps = {
  enabled: boolean;
  message: string;
};

export const BePageOverlayMessage = ({ enabled, message }: BePageOverlayMessageProps) => {
  const [section] = useDebugAttrs(BePageOverlayMessage.name);

  return enabled ? (
    <Block {...section('root')} position={'absolute'} top={0} bottom={0} left={0} right={0}>
      <style {...section('styles')} /* NOTE: don't apply jsx flag here, will prevent injection of the style block */>{`
        @keyframes shimmer {
          100% {
            -webkit-mask-position: left;
          }
        }

        .shimmer-block {
          -webkit-mask: linear-gradient(-60deg, #000000 0%, #000 30%, #0005, #000 70%, #000000 100%) right/300% 100%;
          background-repeat: no-repeat;
          animation: shimmer 2.5s infinite;
        }
      `}</style>

      <Block {...section('overlay')} position={'absolute'} top={0} bottom={0} left={0} right={0}>
        <Block
          position={'fixed'}
          top={0}
          bottom={0}
          left={0}
          right={0}
          backgroundColor={`transparent`}
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          overrides={{
            Block: {
              style: {
                pointerEvents: 'none',
                backdropFilter: 'blur(5px)',
                '-webkit-filter': 'blur(5px)',
              },
            },
          }}
        />

        <Block
          position={'fixed'}
          top={'50vh'}
          width={'100%'}
          overrides={{ Block: { style: { transform: 'translateY(-50%)', textAlign: 'center' } } }}
        >
          <Block
            paddingLeft={'40px'}
            paddingTop={'60px'}
            paddingRight={'40px'}
            paddingBottom={'60px'}
            marginLeft={'auto'}
            marginRight={'auto'}
            overrides={{
              Block: {
                style: {
                  userSelect: 'none',
                  background: `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)`,
                },
              },
            }}
          >
            <Block className={'shimmer-block'}>
              <Image
                width={100}
                height={100}
                alt={'Benefeature logo'}
                src={
                  /* Can't use ikImg here */ `${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/benefeature/logo-icon.svg`
                }
                placeholder={'blur'}
                blurDataURL={`${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/tr:w-10,h-10/benefeature/logo-icon.svg`}
              />
              <DisplayMedium>{message}</DisplayMedium>
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  ) : null;
};
