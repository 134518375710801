import { ApiEnum, HttpStatusEnum } from '@benefeature/shared-common';
import { useEffect, useState } from 'react';
import imageSize from 'buffer-image-size';

// Handle setting the ImageKit cache version for invalidation functionality (BFR-912)
export let IMAGEKIT_GLOBAL_CACHE_VERSION: number = null;
export let FETCHING_IMAGEKIT_GLOBAL_CACHE_VERSION = false;
export const refreshImageKitGlobalCacheVersion = async () => {
  if (FETCHING_IMAGEKIT_GLOBAL_CACHE_VERSION) {
    // Do nothing, another instance will handle setting the cache version
    // Prevents a large number of simultaneous duplicated API requests from being fired at once
  } else {
    FETCHING_IMAGEKIT_GLOBAL_CACHE_VERSION = true;
    if (typeof window === 'undefined') {
      // Running on server
      // Must do an inline import here since otherwise this code can't ever run on the client
      import('../PrismaClient').then((prismaClient) => {
        prismaClient.default.image_cache_version.aggregate({ _max: { version: true } }).then((r) => {
          // noinspection UnnecessaryLocalVariableJS
          const cacheVersion = r?.['_max']?.version || 0;
          IMAGEKIT_GLOBAL_CACHE_VERSION = cacheVersion;
          FETCHING_IMAGEKIT_GLOBAL_CACHE_VERSION = false;
        });
      });
    } else {
      // Running on client
      // Fetch the value from the cache version API endpoint
      fetch(`${process.env.NEXT_PUBLIC_CANONICAL_DOMAIN}${ApiEnum.IMAGE_CACHE_VERSION}`, { cache: 'no-cache' })
        .then((r) => r.json())
        .then((r) => {
          console.debug(
            `Setting image cache version to ${r?.version} after fetch against: ${process.env.NEXT_PUBLIC_CANONICAL_DOMAIN}${ApiEnum.IMAGE_CACHE_VERSION}`
          );
          IMAGEKIT_GLOBAL_CACHE_VERSION = r?.version;
          FETCHING_IMAGEKIT_GLOBAL_CACHE_VERSION = false;
        });
    }
  }
};
refreshImageKitGlobalCacheVersion();

export const DEFAULT_META_IMAGE = '/benefeature/logo-black-1200x630.jpg';

export type ImageKitLayer = {
  // Layer properties
  // Which type of layer this is - only options are image or text
  l: 'image' | 'text';
  // x (px) of the top-left corner in the base asset where the layer's top-left corner would be placed.
  lx?: number | string;
  // y (px) of the top-left corner in the base asset where the layer's top-left corner would be placed.
  ly?: number | string;
  // Position of the layer in relative terms. The default value is center.
  lfo?: 'center' | 'top' | 'left' | 'bottom' | 'right' | 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right';

  // Content field, may either specify text (if l: 'text') or an image path (if l: 'image')
  i?: string;

  // Width (px), may either specify the text width (if l: 'text') or an image width (if l: 'image')
  w?: number | string;

  // Layer image properties (if l: 'image')
  // Height (px)
  h?: number | string;
  // Focus
  fo?: 'center' | 'top' | 'left' | 'bottom' | 'right' | 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right';
  // Trim, true or false to enable/disable or 1-99 to specify the threshold for considering a pixel as background
  t?: boolean | number;
  // Quality, between 1-100, default is 80
  q?: number;

  // Layer text properties (if l: 'text')
  // Color (hex/rgba)
  co?: string;
  // Font size
  fs?: number;
  // Typography
  tg?: 'b' | 'i' | 'b_i';

  // Sub-layers
  nestedLayer?: ImageKitLayer;
};

export type ImageKitTransformationProps = {
  width?: number;
  height?: number;
  aspectRatio?: string;
  padResize?: boolean;
  backgroundColor?: string;
  layer?: ImageKitLayer;
  opacity?: number;
};

/**
 * Takes an ImageKitLayer object and creates a transform string to inject into the ImageKit URL.
 * Calls recursively if a nested layer is found, though note that ImageKit limits nested layers to a depth of 3.
 * See this link for layer implementation details: https://docs.imagekit.io/features/image-transformations/overlay-using-layers
 *
 * @param layer The layer to use for generating the transform string.
 */
export function layerToTransformations(layer: ImageKitLayer): string {
  // Split the layer into the nested layer, the l-type, and any other props
  const { nestedLayer, l, ...otherLayerProps } = layer;

  // Establish any nested layer transformations
  let nestedLayerFmt = '';
  if (nestedLayer) {
    nestedLayerFmt = layerToTransformations(nestedLayer);
  }

  // Format all the other layer properties
  // If there's a %40 '@' character which was encoded, restore it (it's used for establishing a delimiter for overlay image paths)
  const layerFmt = Object.entries(otherLayerProps)
    .map(([k, v]) => {
      return `${k}-${encodeURIComponent(v as string | number | boolean)?.replaceAll('%40', '@')}`;
    })
    .join(',');

  // Return the concatenated and formatted variant
  return `l-${l},${layerFmt}${layerFmt.length > 0 ? ',' : ''}${nestedLayerFmt}${
    nestedLayerFmt.length > 0 ? ',' : ''
  }l-end`;
}

/** Formats a URL based on the environment ImageKit endpoint config and a custom path
 *
 *  todo: likely refactor to imagekit-react and use IKImage directly: https://github.com/imagekit-developer/imagekit-react
 *        note that some images require paths instead of img elements, so there may still need to be another handler
 *
 *  todo: updated 2023-10-02: still no real reason to use the library since URL is consistent and no uploads are needed
 *        shelving transition to library for now
 *
 * @param path
 * @param transformProps
 */
export function ikImg(path: string, transformProps?: ImageKitTransformationProps): string {
  if (path) {
    let transformSegment = '';
    if (transformProps && Object.keys(transformProps).length > 0) {
      transformSegment = `/tr:${Object.entries(transformProps)
        .map(([k, v]) => {
          switch (k) {
            case 'width':
              return `w-${encodeURIComponent(v as string | number | boolean)}`;
            case 'height':
              return `h-${encodeURIComponent(v as string | number | boolean)}`;
            case 'aspectRatio':
              return `ar-${encodeURIComponent(v as string | number | boolean)}`;
            case 'padResize':
              return 'cm-pad_resize';
            case 'backgroundColor':
              return `bg-${encodeURIComponent(v.toString().replace('#', ''))}`;
            case 'layer':
              return layerToTransformations(v as ImageKitLayer);
            case 'opacity':
              return `o-${encodeURIComponent(v as string | number | boolean)}`;
            default:
              return '';
          }
        })
        .filter((x) => x !== '')
        .join(',')}`;
    }

    return `${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}${transformSegment}${path.startsWith('/') ? '' : '/'}${path}${
      IMAGEKIT_GLOBAL_CACHE_VERSION == null
        ? ''
        : `?ver=${process.env.NEXT_PUBLIC_RUNTIME_ENV}-${IMAGEKIT_GLOBAL_CACHE_VERSION}`
    }`;
  } else {
    return '';
  }
}

// Formats a URL based on the environment ImageKit endpoint config and a logos prefix
export function ikLogo(domain, transformProps?: ImageKitTransformationProps): string {
  if (domain) {
    return ikImg(getIkLogoPath(domain), transformProps) ?? '';
  } else {
    return '';
  }
}

export function getIkLogoPath(domain, isOverlay = false) {
  return isOverlay ? `logos@@${domain}.png` : `/logos/${domain}.png`;
}

const BASE_IMAGE_DEFAULT_PATH = '/benefeature/logo-black-amp-offset-1200x630.jpg';
const BASE_IMAGE_WIDTH = 1200;
const OVERLAY_LOGO_WIDTH = 256;
const OVERLAY_TEXT_SIZE = 100;
const OVERLAY_TEXT_MAX_WIDTH = 1100;
const OVERLAY_TEXT_MAX_CHARS_PER_LINE = 19;
const OVERLAY_TEXT_MAX_LINES = 2;
const OVERLAY_TEXT_REGEX_MATCH = new RegExp(`^.{${OVERLAY_TEXT_MAX_CHARS_PER_LINE}}\\w*`);
const OVERLAY_TEXT_MULTI_LINE_Y_OFFSET = 340;
const OVERLAY_TEXT_SINGLE_LINE_Y_OFFSET = 380;

export async function getEntityMetaImage(entityName?: string, entityDomain?: string) {
  // Check if the domain corresponds with an image
  const domainHasImage = entityDomain && entityDomain.length > 0 && (await usableImage(ikLogo(entityDomain)));

  const useLogo = domainHasImage || entityName ? BASE_IMAGE_DEFAULT_PATH : DEFAULT_META_IMAGE;

  const useEntityName = entityName
    ? entityName.length > OVERLAY_TEXT_MAX_CHARS_PER_LINE * OVERLAY_TEXT_MAX_LINES
      ? entityName.match(OVERLAY_TEXT_REGEX_MATCH).slice(0, OVERLAY_TEXT_MAX_LINES).join(' ')
      : entityName
    : null;

  // Define either the logo to use or a text overlay instead if a logo is unavailable
  const overlay: ImageKitLayer = domainHasImage
    ? {
        l: 'image',
        lfo: 'bottom',
        i: getIkLogoPath(entityDomain, true),
        q: 100,
        t: false,
        w: OVERLAY_LOGO_WIDTH,
      }
    : entityName
    ? {
        l: 'text',
        lx: (BASE_IMAGE_WIDTH - OVERLAY_TEXT_MAX_WIDTH) / 2,
        ly:
          useEntityName.length > OVERLAY_TEXT_MAX_CHARS_PER_LINE
            ? OVERLAY_TEXT_MULTI_LINE_Y_OFFSET
            : OVERLAY_TEXT_SINGLE_LINE_Y_OFFSET,
        i: `${useEntityName}${useEntityName === entityName ? '' : '...'}`,
        fs: OVERLAY_TEXT_SIZE,
        w: OVERLAY_TEXT_MAX_WIDTH,
        tg: 'b',
      }
    : null;

  return ikImg(useLogo, {
    ...(overlay ? { layer: overlay } : {}),
    backgroundColor: 'white',
  });
}

export function useEntityMetaImage(name: string, domain: string) {
  const [metaImage, setMetaImage] = useState(DEFAULT_META_IMAGE);

  useEffect(() => {
    getEntityMetaImage(name, domain).then((img) => setMetaImage(img ?? ''));
  }, [name, domain]);

  return metaImage;
}

export async function usableImage(url, minAcceptableHeight = 200): Promise<boolean> {
  if (url) {
    const res = await fetch(url);

    if (res.status === HttpStatusEnum.OK) {
      const imgBlob = await res.blob();

      if (imgBlob && imgBlob.size > 0 && imgBlob.type?.startsWith('image/')) {
        // Leverage a blob buffer and calculate the image size from it
        const blobBuffer = await imgBlob.arrayBuffer().then((arrayBuffer) => Buffer.from(arrayBuffer));
        const sizes = imageSize(blobBuffer);

        // Return true if conditions are met
        return sizes.height >= minAcceptableHeight;
      }
    }
  }
  return false;
}
