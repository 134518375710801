export const UIHelpers = {
  isMacintosh: () => {
    return typeof window !== 'undefined' && navigator.platform.indexOf('Mac') > -1;
  },

  isWindows: () => {
    return typeof window !== 'undefined' && navigator.platform.indexOf('Win') > -1;
  },

  toBasePath: (path) => {
    if (path) {
      const _pathSliceLength = Math.min(
        path.indexOf('?') > 0 ? path.indexOf('?') : path.length,
        path.indexOf('#') > 0 ? path.indexOf('#') : path.length
      );
      return path.substring(0, _pathSliceLength);
    }
    return null;
  },

  waitForElement: (selector) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  },

  /**
   * Absolute difference in days between two dates.
   *
   * @param d1
   * @param d2
   */
  dateDiffDays: function (d1: Date, d2: Date) {
    const t2 = d2.getTime();
    const t1 = d1.getTime();

    return Math.floor(Math.abs(t2 - t1) / (24 * 3600 * 1000));
  },

  /**
   * Absolute difference in weeks between two dates.
   *
   * @param d1
   * @param d2
   */
  dateDiffWeeks: function (d1: Date, d2: Date) {
    const t2 = d2.getTime();
    const t1 = d1.getTime();

    return Math.floor(Math.abs(t2 - t1) / (24 * 3600 * 1000 * 7));
  },

  dateDiffMonths: function (d1: Date, d2: Date) {
    const d1Y = d1.getFullYear();
    const d2Y = d2.getFullYear();
    const d1M = d1.getMonth();
    const d2M = d2.getMonth();

    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
  },

  dateDiffYears: function (d1: Date, d2: Date) {
    return d2.getFullYear() - d1.getFullYear();
  },
};
