import { ActionType } from '../../models';
import { UIConfigActionTypes } from './uiConfig.actions';
import { Theme } from 'baseui';

export interface UIConfigReducerState {
  sidebarOpen: boolean;
  detailFilterPanesOpen: boolean;
  selectedDashboardPeer: string;
  selectedTheme: Theme;
}

const initialState: UIConfigReducerState = {
  sidebarOpen: true,
  detailFilterPanesOpen: true,
  selectedDashboardPeer: null,
  selectedTheme: null,
};

const uiConfigReducer = (state = initialState, action: ActionType): UIConfigReducerState => {
  switch (action.type) {
    case UIConfigActionTypes.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.payload,
      };

    case UIConfigActionTypes.TOGGLE_DETAIL_FILTER_PANES:
      return {
        ...state,
        detailFilterPanesOpen: action.payload,
      };

    case UIConfigActionTypes.SELECT_DASHBOARD_PEER:
      return {
        ...state,
        selectedDashboardPeer: action.payload,
      };

    case UIConfigActionTypes.SELECT_THEME:
      return {
        ...state,
        selectedTheme: action.payload,
      };

    default:
      return state;
  }
};

export default uiConfigReducer;
