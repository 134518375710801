import { StatefulPopover, StatefulPopoverProps, TRIGGER_TYPE } from 'baseui/popover';
import { ReactNode, useState } from 'react';
import { BeTooltip, BeTooltipProps } from './BeTooltip';
import { Block } from 'baseui/block';
import * as React from 'react';
import { mergeOverrides } from 'baseui';
import { useDebugAttrs } from '../hooks';

export type BePopoverWithTooltipProps = {
  children: ReactNode;
  tooltipProps: Omit<BeTooltipProps, 'children'>;
  popoverProps: Omit<StatefulPopoverProps, 'children'>;
  appendToBody?: boolean;
};

/**
 * Creates a nested structure where the popover wraps the tooltip instead of the reverse.
 *
 * This allows for hover/click tooltips to be dismissed when the popover is triggered instead
 * of continuing to exist behind it.
 *
 * It also tracks the state between the popover and tooltip, nulling the tooltip content
 * entirely to prevent inadvertent display while the popover is open.
 */
export const BePopoverWithTooltip = ({
  children,
  tooltipProps,
  popoverProps,
  appendToBody = false,
}: BePopoverWithTooltipProps) => {
  const [section] = useDebugAttrs(BePopoverWithTooltip.name);
  const [allowTooltip, setAllowTooltip] = useState(true);

  /* Pull the body HTML element for usage in mounting the popover */
  let bodyHTMLElement;
  if (appendToBody && typeof window !== 'undefined') {
    bodyHTMLElement = document.querySelector('body');
  }

  return (
    <StatefulPopover
      {...section('popover')}
      renderAll
      returnFocus
      dismissOnClickOutside
      popoverMargin={16}
      triggerType={TRIGGER_TYPE.click}
      {...popoverProps}
      {...(bodyHTMLElement
        ? {
            /* If the body element is found, mount to it and keep it inside */
            mountNode: bodyHTMLElement,
            ignoreBoundary: false,
          }
        : {})}
      popperOptions={{}}
      overrides={mergeOverrides(
        {
          Body: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.backgroundPrimary,
            }),
          },
        },
        popoverProps.overrides || {}
      )}
      onOpen={() => {
        setAllowTooltip(false);

        // If props also provided an onOpen, call it
        if (popoverProps.onOpen) {
          popoverProps.onOpen();
        }
      }}
      onClose={() => {
        setAllowTooltip(true);

        // If props also provided an onClose, call it
        if (popoverProps.onClose) {
          popoverProps.onClose();
        }
      }}
    >
      {/* Block provides target ref for popover */}
      <Block {...section('popover-block-target')}>
        {allowTooltip ? (
          <BeTooltip
            {...section('tooltip')}
            dismissOnClickOutside
            dismissOnEsc
            {...tooltipProps}
            content={tooltipProps.content}
          >
            {/* Block provides target ref for tooltip */}
            <Block {...section('tooltip-block-target')}>{children}</Block>
          </BeTooltip>
        ) : (
          children
        )}
      </Block>
    </StatefulPopover>
  );
};
