// Defines methods required for generic fileExport behavior
import useSWR from 'swr';
import { ApiEnum } from '@benefeature/shared-common';
import { useEffect, useState } from 'react';

const USER_EXPORT_REFRESH_INTERVAL_MS = 3000;

/**
 * Hook for pulling user export statuses and details.
 * todo evaluate adding status to response
 *
 * @param userID
 * @param callback
 */
export function useUserExports(userID: string, callback?: (any) => any) {
  // Create a hook to poll the API and return any values via SWR
  const { data: exports, isLoading: loadingExports } = useSWR(
    userID ? ApiEnum.USER_EXPORTS.replace('[uid]', userID) : '',
    {
      refreshInterval: USER_EXPORT_REFRESH_INTERVAL_MS,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  const [exportsState, setExportsState] = useState(exports);

  useEffect(() => {
    if (!loadingExports) {
      if (callback) {
        callback(exports);
      }
      setExportsState(exports);
    }
  }, [exports, loadingExports, callback]);

  return exportsState;
}

export async function deleteAllExports(userID: string) {
  if (userID) {
    const response = await fetch(ApiEnum.USER_EXPORTS.replace('[uid]', userID), { method: 'delete' });
    console.debug('Delete all exports response:', response);
  }
}

export async function deleteExport(exportID: string, userID: string) {
  if (userID && exportID) {
    const response = await fetch(`${ApiEnum.USER_EXPORTS.replace('[uid]', userID)}?export_id=${exportID}`, {
      method: 'DELETE',
    });
    console.debug(`Delete export ${exportID} response:`, response);
  }
}

export function downloadExport(exportID: string, userID: string, openInNewTab = false) {
  fetch(ApiEnum.USER_EXPORT_DOWNLOAD_LINK.replace('[uid]', userID).replace('[export_id]', exportID))
    .then((res) => res.json())
    .then((downloadLink) => {
      console.debug('Export ID', exportID, 'signed download URL:', downloadLink);
      // Start the download via the browser's built-in capabilities (no separate manager)
      // This call only results in the browser starting the download - no new window is opened and the current route remains
      if (openInNewTab) {
        window.open(downloadLink, '_blank');
      } else {
        window?.location?.assign(downloadLink);
      }
    });
}

export function downloadStaticRef(refPath: string, openInNewTab = false) {
  fetch(`${ApiEnum.STATIC_REFS_DOWNLOAD_LINK}?ref_path=${encodeURIComponent(refPath)}`)
    .then((res) => res.json())
    .then((downloadLink) => {
      console.debug('Data extract reference path', refPath, 'signed download URL:', downloadLink);
      // Start the download via the browser's built-in capabilities (no separate manager)
      // This call only results in the browser starting the download - no new window is opened and the current route remains
      if (openInNewTab) {
        window.open(downloadLink, '_blank');
      } else {
        window?.location?.assign(downloadLink);
      }
    });
}
