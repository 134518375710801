import { ReactNode, useState } from 'react';
import { BeAvatar } from '../../components';
import { BeButtonLink } from '../../forms';
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { LabelMedium } from 'baseui/typography';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Block } from 'baseui/block';
import { useBreakpoint } from '../../hooks';
import { BaseWebHelpers } from '../../helpers';
import { useStyletron } from 'baseui';

export type BeAvatarMenuProps = {
  menuItems?: { label: ReactNode; [name: string]: any }[];
  icon?: string;
  name?: string;
  infoPanel?: ReactNode;
  onItemClick?: (item?: any) => void;
  isSquare?: boolean;
  noBorder?: boolean;
  noRounding?: boolean;
  avatarSize?: string;
  overlayIcon?: ReactNode;
  innerWidth?: string;
  hideChevron?: boolean;
};

export const BeAvatarMenu = ({
  menuItems,
  icon,
  name,
  infoPanel,
  onItemClick,
  isSquare,
  noBorder,
  noRounding,
  avatarSize,
  overlayIcon,
  innerWidth = '175px',
  hideChevron,
}: BeAvatarMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDropdown = Array.isArray(menuItems) && !!menuItems.length;

  const onClick = (item?: any) => {
    onItemClick && onItemClick(item);
  };

  if (!isDropdown) {
    return (
      <BeButtonLink
        kind={'tertiary'}
        overrides={{
          BaseButton: {
            style: {
              verticalAlign: 'middle',
              ...(avatarSize
                ? {
                    ...BaseWebHelpers.padding('2px'),
                  }
                : {}),
            },
          },
        }}
        onClick={() => onClick()}
      >
        <AvatarHelperComponent
          icon={icon}
          menuItems={menuItems}
          name={name}
          isOpen={isOpen}
          isSquare={isSquare}
          noBorder={noBorder}
          noRounding={noRounding}
          onItemClick={onClick}
          avatarSize={avatarSize}
          overlayIcon={overlayIcon}
          hideChevron={hideChevron}
        />
      </BeButtonLink>
    );
  } else {
    return (
      <StatefulPopover
        returnFocus
        dismissOnClickOutside={true}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        placement={PLACEMENT.bottomRight}
        popperOptions={{ modifiers: { flip: { enabled: false } } }}
        triggerType={TRIGGER_TYPE.click}
        overrides={{
          Body: {
            style: {
              marginTop: '2px',
            },
          },
          Inner: {
            style: ({ $theme }) => ({
              backgroundColor: $theme.colors.white,
              width: innerWidth,
              ...BaseWebHelpers.borderRadius('8px'),
            }),
          },
        }}
        content={({ close }) => {
          if (isDropdown) {
            return (
              <Block
                overrides={{
                  Block: {
                    style: {
                      ...BaseWebHelpers.borderRadius('8px'),
                    },
                  },
                }}
              >
                {infoPanel && (
                  <Block
                    overrides={{
                      Block: {
                        style: {
                          fontSize: '16px',
                        },
                      },
                    }}
                  >
                    {infoPanel}
                  </Block>
                )}
                <StatefulMenu
                  items={menuItems as any[]}
                  onItemSelect={({ item }) => {
                    onClick(item);
                    close();
                  }}
                  overrides={{
                    List: {
                      style: {
                        ...BaseWebHelpers.borderRadius('8px'),
                        ':focus': {
                          outline: 'none',
                        },
                      },
                    },
                    ListItem: {
                      style: ({ $theme }) => ({
                        paddingLeft: '22px',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: $theme.colors.contentPrimary,
                        lineHeight: '40px',
                      }),
                    },
                  }}
                />
              </Block>
            );
          } else {
            close();
            return null;
          }
        }}
      >
        <BeButtonLink
          kind={'tertiary'}
          overrides={{
            BaseButton: {
              style: {
                verticalAlign: 'middle',
                ...(avatarSize
                  ? {
                      ...BaseWebHelpers.padding('2px'),
                    }
                  : {}),
              },
            },
          }}
        >
          <AvatarHelperComponent
            icon={icon}
            menuItems={menuItems}
            name={name}
            isOpen={isOpen}
            isSquare={isSquare}
            noBorder={noBorder}
            noRounding={noRounding}
            avatarSize={avatarSize}
            overlayIcon={overlayIcon}
            hideChevron={hideChevron}
          />
        </BeButtonLink>
      </StatefulPopover>
    );
  }
};

const AvatarHelperComponent = ({
  name,
  icon,
  menuItems,
  isOpen,
  isSquare,
  noBorder,
  noRounding,
  avatarSize,
  overlayIcon,
  hideChevron,
}: BeAvatarMenuProps & { isOpen: boolean }) => {
  const [css, $theme] = useStyletron();
  const isDropdown = Array.isArray(menuItems) && !!menuItems.length;
  const isMobileView = useBreakpoint() === 'small';
  return (
    <>
      <Block position={'relative'}>
        <BeAvatar
          name={name || '?'}
          size={avatarSize || '48px'}
          verticalMiddle={true}
          src={icon}
          isSquare={isSquare}
          noBorder={noBorder}
          noRounding={noRounding}
        />
        {overlayIcon && (
          <Block color={'gray'} position={'absolute'} bottom={'-5px'} right={'-5px'}>
            {overlayIcon}
          </Block>
        )}
      </Block>
      {isDropdown && (
        <>
          {!isMobileView && name && (
            <LabelMedium
              color={'black'}
              marginLeft={'scale300'}
              overrides={{ Block: { style: { userSelect: 'none' } } }}
            >
              {name}
            </LabelMedium>
          )}
          {!hideChevron &&
            (isOpen ? (
              <ExpandLessRoundedIcon style={{ fill: $theme.colors.contentSecondary }} />
            ) : (
              <ExpandMoreRoundedIcon style={{ fill: $theme.colors.contentSecondary }} />
            ))}
        </>
      )}
    </>
  );
};
