import React from 'react';
import { Avatar, AvatarOverrides, AvatarProps } from 'baseui/avatar';
import { expandBorderStyles } from 'baseui/styles';
import { BaseWebHelpers } from '../helpers';

export type BeAvatarProps = Omit<AvatarProps, 'overrides'> & {
  isSquare?: boolean;
  noBorder?: boolean;
  noRounding?: boolean;
  verticalMiddle?: boolean;
};

export const BeAvatar = ({ isSquare, noBorder, noRounding, verticalMiddle, ...props }: BeAvatarProps) => {
  const customOverrides: AvatarOverrides = {
    Root: {
      style: ({ $theme }) => ({
        backgroundColor: 'transparent',
        color: $theme.colors.contentSecondary,
        ...(verticalMiddle && {
          verticalAlign: 'middle',
        }),
        ...(isSquare && {
          ...BaseWebHelpers.borderRadius(noRounding ? 0 : $theme.borders.radius300),
        }),
      }),
    },
    Avatar: {
      props: {
        referrerPolicy: 'no-referrer',
      },
      style: ({ $theme }) => ({
        userSelect: 'none',
        pointerEvents: 'none',
        height: 'auto',
        width: props.size || '48px',
        backgroundColor: 'transparent',
        color: $theme.colors.contentSecondary,
        /* Setting line height directly is required to prevent spacing below image in container */
        lineHeight: props.size || '48px',
        ...(!noBorder && {
          ...expandBorderStyles($theme.borders.border200),
        }),
        ...(isSquare && {
          ...BaseWebHelpers.borderRadius(noRounding ? 0 : $theme.borders.radius300),
        }),
      }),
    },
    Initials: {
      style: ({ $theme }) => ({
        userSelect: 'none',
        pointerEvents: 'none',
        backgroundColor: $theme.colors.backgroundSecondary,
        color: $theme.colors.contentSecondary,
        ...(!noBorder && {
          ...expandBorderStyles($theme.borders.border200),
        }),
        ...(isSquare && {
          ...BaseWebHelpers.borderRadius(noRounding ? 0 : $theme.borders.radius300),
        }),
      }),
    },
  };

  // Clean up the name for initials if necessary
  const useName = props.name?.replace(/[\W_]+/g, ' ');

  return (
    <Avatar
      overrides={{ ...customOverrides }}
      {...props}
      /* Below overrides name variant in props */
      name={useName}
    />
  );
};

// const StyledAvatar = styled(Avatar, ({ $theme,  isSquare}) => ({
//   ...expandBorderStyles($theme.borders.border100),
// }));
