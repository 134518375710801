import { AppLayout } from './AppLayout';
import { ProtectedPage } from '../ProtectedPage';
import { PERMISSIONS } from '@benefeature/shared-common';

export const ProtectedDefaultLayout = ({ children, ...props }) => {
  const protectedPageRoles = [{ hasPermissions: [PERMISSIONS.IS_AUTHENTICATED] }];

  return (
    <ProtectedPage rolesWithAccess={protectedPageRoles}>
      <AppLayout {...props}>{children}</AppLayout>
    </ProtectedPage>
  );
};
