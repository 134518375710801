/*
Partly Copied from node_modules/baseui/button/styled-components.js.flow
*/

import { KIND, SHAPE, SIZE } from 'baseui/button';
import { StyleObject } from 'styletron-react';

export const getBaseStyles = ({
  $theme,
  $size,
  $kind,
  $shape,
  $isLoading,
  $isSelected,
  $disabled,
  $isFocusVisible,
  variant,
}: any): any => {
  const colors = $theme.colors.variants.button[variant] || $theme.colors;
  return {
    boxShadow: $isFocusVisible ? `inset 0 0 0 3px ${$theme.colors.accent}` : 'none',
    height: 'auto',
    width: 'auto',
    userSelect: 'none',
    // Kind style override
    ...getFontStyles({ $theme, $size }),
    ...getKindStyles({ colors, $kind, $isLoading, $isSelected, $disabled }),
    ...getPaddingStyles({ $theme, $size, $shape }),
  };
};

function getKindStyles({ colors, $isLoading, $isSelected, $kind, $disabled }: any): StyleObject {
  if ($disabled) {
    return Object.freeze({});
  }
  switch ($kind) {
    case KIND.primary:
      if ($isSelected) {
        return {
          color: colors.buttonPrimarySelectedText,
          backgroundColor: colors.buttonPrimarySelectedFill,
          ':hover': {
            textDecoration: 'none',
          },
        };
      }
      return {
        color: colors.buttonPrimaryText,
        backgroundColor: colors.buttonPrimaryFill,
        ':hover': {
          backgroundColor: $isLoading ? colors.buttonPrimaryActive : colors.buttonPrimaryHover,
          textDecoration: 'none',
        },
        ':active': {
          backgroundColor: colors.buttonPrimaryActive,
        },
      };
    case KIND.secondary:
      if ($isSelected) {
        return {
          color: colors.buttonPrimaryText,
          backgroundColor: colors.buttonPrimaryFill,
          ':hover': {
            textDecoration: 'none',
          },
        };
      }
      return {
        color: colors.buttonSecondaryText,
        backgroundColor: colors.buttonSecondaryFill,
        ':hover': {
          backgroundColor: $isLoading ? colors.buttonSecondaryActive : colors.buttonSecondaryHover,
          textDecoration: 'none',
        },
        ':active': {
          backgroundColor: colors.buttonSecondaryActive,
        },
      };
    case KIND.tertiary:
      if ($isSelected) {
        return {
          color: colors.buttonTertiarySelectedText,
          backgroundColor: colors.buttonTertiarySelectedFill,
          ':hover': {
            textDecoration: 'none',
          },
        };
      }
      return {
        color: colors.buttonTertiaryText,
        backgroundColor: colors.buttonTertiaryFill,
        ':hover': {
          // backgroundColor: $isLoading ? colors.buttonTertiaryActive : colors.buttonTertiaryHover,
          textDecoration: 'none',
        },
        ':active': {
          // backgroundColor: colors.buttonTertiaryActive,
        },
      };
    default:
      return Object.freeze({});
  }
}

function getPaddingStyles({ $theme, $size, $shape }: any): StyleObject {
  const iconShape = $shape === SHAPE.square || $shape === SHAPE.circle || $shape === SHAPE.round;
  switch ($size) {
    case SIZE.mini:
      return {
        paddingTop: $theme.sizing.scale100,
        paddingBottom: $theme.sizing.scale100,
        paddingLeft: iconShape ? $theme.sizing.scale200 : $theme.sizing.scale300,
        paddingRight: iconShape ? $theme.sizing.scale200 : $theme.sizing.scale300,
      };
    case SIZE.compact:
      return {
        paddingTop: $theme.sizing.scale300,
        paddingBottom: $theme.sizing.scale300,
        paddingLeft: iconShape ? $theme.sizing.scale400 : $theme.sizing.scale500,
        paddingRight: iconShape ? $theme.sizing.scale400 : $theme.sizing.scale500,
      };
    case SIZE.large:
      return {
        paddingTop: $theme.sizing.scale500,
        paddingBottom: $theme.sizing.scale500,
        paddingLeft: iconShape ? $theme.sizing.scale500 : $theme.sizing.scale600,
        paddingRight: iconShape ? $theme.sizing.scale500 : $theme.sizing.scale600,
      };
    default:
      return {
        paddingTop: $theme.sizing.scale400,
        paddingBottom: $theme.sizing.scale400,
        paddingLeft: iconShape ? $theme.sizing.scale400 : $theme.sizing.scale500,
        paddingRight: iconShape ? $theme.sizing.scale400 : $theme.sizing.scale500,
      };
  }
}

function getFontStyles({ $theme, $size }: any): StyleObject {
  switch ($size) {
    case SIZE.mini:
      return $theme.typography.font150;
    case SIZE.compact:
      return $theme.typography.font150;
    case SIZE.large:
      return $theme.typography.font350;
    default:
      return $theme.typography.font250;
  }
}
