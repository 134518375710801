// Defines methods required for generic notification behavior
import { ApiEnum } from '@benefeature/shared-common';
import useSWR from 'swr';
import { setCurrentNotifications, store } from '@benefeature/shared/ui';
import { useEffect, useState } from 'react';
import { BeNotification } from '@benefeature/shared-types';

const USER_NOTIFICATION_REFRESH_INTERVAL = 2000;

export function useUserNotifications(userID: string | undefined, callback?: (any) => any) {
  // Create a hook to poll the API and return any values via SWR
  const { data: notifications, isLoading: loadingNotifications } = useSWR(
    userID ? ApiEnum.USER_NOTIFICATIONS.replace('[uid]', userID) : '',
    {
      refreshInterval: USER_NOTIFICATION_REFRESH_INTERVAL,
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  const [notificationsState, setNotificationsState] = useState(notifications);

  useEffect(() => {
    if (!loadingNotifications) {
      if (callback) {
        callback(notifications);
      }
      setNotificationsState(notifications);
    }
  }, [notifications, loadingNotifications, callback]);

  return notificationsState;
}

export function dismissAllNotifications(userID: string | undefined) {
  if (userID) {
    // Immediately clear out the notifications store by setting an empty array
    store.dispatch(setCurrentNotifications([]));

    // Issue a request to the endpoint to delete existing notifications
    // Note that this is not guaranteed to succeed
    // A failure to clear the notifications will result in the next refresh tick restoring all which were deleted from the store dispatch
    return fetch(ApiEnum.USER_NOTIFICATIONS.replace('[uid]', userID), { method: 'DELETE' });
  }
}

export function addNotification(
  userID: string | undefined,
  notification: Omit<BeNotification, 'id' | 'updated_timestamp'>
) {
  if (userID && notification) {
    return fetch(ApiEnum.USER_NOTIFICATIONS.replace('[uid]', userID), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(notification, null, 2),
    });
  }
}
