import { Session } from 'next-auth';
import * as Sentry from '@sentry/browser';

export const trackSentryUser = async (session: Session) => {
  // Identify the user on Sentry if one is available
  if (session?.user) {
    // Tag all applicable attributes that'll help correlate activity to a user
    // Segmentation is just done at a team level if available
    Sentry.setUser({
      email: session?.user?.email,
      id: session?.user?.id,
      username: session?.user?.name,
      segment: session?.user?.team?.name,
    });
  }
};

export const trackHubSpotPageView = async (session: Session, path: string) => {
  // Grab the _hsq variable from the window, loaded by JS in _app.tsx
  const _hsq = ((window as any)._hsq = (window as any)._hsq || []);

  // If the current user has an email address, identify them
  if (session?.user?.email) {
    let firstName = null;
    let lastName = null;
    if (session?.user?.name) {
      const splitName = session?.user?.name?.split(' ');
      firstName = splitName[0];
      lastName = splitName.length > 1 ? splitName.slice(1).join(' ') : null;
    }
    _hsq.push([
      'identify',
      {
        email: session?.user?.email,
        firstname: firstName,
        lastname: lastName,
      },
    ]);
  }

  // Set the path and track the page view
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);
};
