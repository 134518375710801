import { HttpStatusEnum } from '@benefeature/shared-common';

export const SWR_DEDUPING_INTERVAL_MS = 2000;
export const SWR_LOADING_TIMEOUT_MS = 2000;
export const SWR_ONERROR_RETRY_STATUS_BLACKLIST = [HttpStatusEnum.NOT_FOUND];
export const SWR_ONERROR_RETRY_KEY_BLACKLIST = [];
export const SWR_ONERROR_RETRY_DELAY_MS = 200;
export const SWR_ONERROR_RETRY_MAX_ATTEMPTS = 10;

export const defaultSwrFetcher = (resource, init) =>
  fetch(resource, init).then((res) => {
    // If the status code is not in the range 200-299, do not return the content, but do log the issue
    if (res.ok) {
      return res.json();
    } else {
      // Attach extra info to the error object.
      console.error(defaultSwrFetcher.name, ': Error on resource', resource, 'status code', res.status);
      console.error(res.json());
      return null;
    }
  });

export const defaultSwrOnErrorRetry = (error, key, config, revalidate, { retryCount }) => {
  // Blacklist retries on specific statuses e.g. 404
  if (SWR_ONERROR_RETRY_STATUS_BLACKLIST.includes(error.status)) {
    return;
  }

  // Blacklist specific keys e.g. /api/user
  if (SWR_ONERROR_RETRY_KEY_BLACKLIST.includes(key)) {
    return;
  }

  // Only retry up to some number of times
  if (retryCount >= SWR_ONERROR_RETRY_MAX_ATTEMPTS) {
    return;
  }

  // Retry after a slight delay
  setTimeout(() => revalidate({ retryCount }), SWR_ONERROR_RETRY_DELAY_MS);
};
