import { RouteEnum } from '@benefeature/shared-common';
import { BeButtonLink, BeButtonLinkProps } from '@benefeature/shared/ui';

interface SignUpButtonProps extends BeButtonLinkProps {
  priceID: string;
}

/* Provides a route to AUTH_SIGNUP with the given priceID.
 * Relies on AUTH_SIGNUP properly redirecting based on session status. */
export default function SignUpButton({ priceID, children = 'Sign Up', ...props }: SignUpButtonProps) {
  return (
    <BeButtonLink
      kind={'tertiary'}
      {...props}
      href={`${RouteEnum.AUTH_SIGNUP}${priceID ? `?price_id=${priceID}` : ''}`}
    >
      {children}
    </BeButtonLink>
  );
}
