import { ActionType } from '../../models';
import { UserNotifications } from '@benefeature/shared-types';

export enum NotificationsActionTypes {
  SET_NOTIFICATIONS_VIEWED = '[Notifications] Set whether the current notifications have been viewed',
  SET_CURRENT_NOTIFICATIONS = '[Notifications] Set the current notifications',
  SET_ANY_NOTIFICATIONS_OPEN = '[Notifications] Whether the user is viewing notifications in any tab',
}

export const setNotificationsViewed = (payload: boolean): ActionType => ({
  type: NotificationsActionTypes.SET_NOTIFICATIONS_VIEWED,
  payload,
});

export const setCurrentNotifications = (payload: UserNotifications): ActionType => ({
  type: NotificationsActionTypes.SET_CURRENT_NOTIFICATIONS,
  payload,
});

export const setAnyNotificationsOpen = (payload: boolean): ActionType => ({
  type: NotificationsActionTypes.SET_ANY_NOTIFICATIONS_OPEN,
  payload,
});
