import { BeAvatar, BeButton, BeLockedContentOverlay, BeRadioButton, BeSelect } from '@benefeature/shared/ui';
import { useStyletron } from 'baseui';
import { ApiEnum, EntityType, getEntityBestName } from '@benefeature/shared-common';
import { Block } from 'baseui/block';
import useSWR from 'swr';
import React, { useEffect, useState } from 'react';
import { SearchResponse } from '@benefeature/shared-types';
import { Option } from 'baseui/select';
import { LabelSmall, LabelXSmall, ParagraphMedium } from 'baseui/typography';
import { ikLogo } from '../../core/factories/image.factory';
import { useSession } from 'next-auth/react';
import { subscribedOrAdmin } from '../../core/services/user-state.service';

const entityTypeToEntityID = {
  // [EntityType.EMPLOYER]: 'company-id',
  [EntityType.CARRIER]: 'carrier-master-id',
  [EntityType.BROKER]: 'broker-master-id',
};

const resultsToOptions = (results: any[], entityType: EntityType) => {
  return (results || []).map((res) => {
    const name = getEntityBestName(res, entityType);

    return {
      id: res[entityTypeToEntityID[entityType]],
      value: name,
      label: (
        <Block display={'flex'} alignItems={'center'} gridColumnGap={'16px'} justifyContent={'space-between'}>
          <span>
            {name}
            {entityType === EntityType.BROKER && res['parent'] && name.toLowerCase() === res['parent'].toLowerCase() ? (
              <span color={'gray'}>&nbsp;(Direct)</span>
            ) : null}
          </span>
          <BeAvatar
            isSquare
            noBorder
            noRounding
            src={ikLogo(res['cb-domain'], { width: 96 })}
            name={name}
            size={'32px'}
          />
        </Block>
      ),
      ...res,
    };
  });
};

export interface ContextSelectProps {
  title?: string;
  userID?: string;
  teamID?: string;
  afterChangeFn?: () => any;
  unlockForSetup?: boolean;
  minWidth?: string;
}

const ContextSelect = ({
  title = 'Update Context',
  userID,
  teamID,
  afterChangeFn,
  unlockForSetup,
  minWidth = '300px',
}: ContextSelectProps) => {
  const [, /*css*/ $theme] = useStyletron();

  // Despite this component being able to update users and teams besides the current,
  // a frequent use case is to update the current user's context
  // Update the session if a change is made and it's for this user
  const { data: session, update: updateSession, status: sessionStatus } = useSession();

  // Check if this functionality should appear as locked
  const [locked, setLocked] = useState(false);
  useEffect(() => {
    if (sessionStatus !== 'loading') {
      setLocked(!unlockForSetup && session?.user?.current_context_entity_name && !subscribedOrAdmin(session));
    }
  }, [unlockForSetup, session, sessionStatus]);

  const [query, setQuery] = useState('');
  const [entityType, setEntityType] = useState(EntityType.CARRIER);

  const entityTypeToApi = {
    // [EntityType.EMPLOYER]: ApiEnum.SEARCH_EMPLOYERS,
    [EntityType.CARRIER]: ApiEnum.SEARCH_CARRIERS,
    [EntityType.BROKER]: ApiEnum.SEARCH_BROKERS,
  };

  // Run the query on load - display controlled by whether there's actually content
  // Store options separately
  const {
    data: searchResults,
    isLoading: searchIsLoading,
    isValidating: searchIsValidating,
  } = useSWR(locked ? null : entityTypeToApi[entityType] + `?query=${query}&limit=10`);
  const [availableOptions, setAvailableOptions] = useState<any[]>([]);
  useEffect(() => {
    if (!searchIsLoading && !searchIsValidating) {
      setAvailableOptions(resultsToOptions((searchResults as SearchResponse)?.searchResults, entityType) || []);
    }
  }, [searchIsLoading, searchIsValidating, searchResults, entityType]);

  // Set up handling of the search input
  // Debounce inputs to prevent a barrage of requests against the endpoint
  const debounceDelay = 400;
  const [timerID, setTimerID] = useState(null);

  function onInputChangeDebounced(input) {
    const _interimValue = input;
    clearTimeout(timerID);
    setTimerID(
      setTimeout(() => {
        setQuery(_interimValue);
        setTimerID(null);
      }, debounceDelay)
    );
  }

  const [selectedOption, setSelectedOption] = useState<Option>(null);

  const [submitting, setSubmitting] = useState(false);

  function completeSubmit() {
    setSubmitting(false);
    setSelectedOption(null);
    setQuery('');

    if (session?.user?.id === userID || session?.user?.team_id === teamID) {
      // Update the session itself if this is the user or the user's team
      updateSession();
    }

    if (afterChangeFn) {
      afterChangeFn();
    }
  }

  function submit() {
    if (selectedOption) {
      setSubmitting(true);

      const requestHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const requestBody = JSON.stringify({ entityID: selectedOption.id, entityType: entityType });
      const apiRoute = userID
        ? ApiEnum.USER_CONTEXT.replace('[uid]', userID)
        : ApiEnum.TEAM_CONTEXT.replace('[team_id]', teamID);

      fetch(apiRoute, {
        method: 'PATCH',
        headers: requestHeaders,
        body: requestBody,
      })
        .then(() => console.log(`Successfully updated context to ${requestBody}`))
        .finally(completeSubmit);
    } else {
      console.error('Submitted without having a new selected context');
    }
  }

  return (
    <Block
      backgroundColor={$theme.colors.backgroundPrimary}
      margin={'4px'}
      padding={'12px'}
      display={'flex'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      justifyContent={'center'}
      gridRowGap={'16px'}
      minWidth={minWidth}
    >
      <LabelSmall>{title}</LabelSmall>

      {!unlockForSetup && !session?.user?.current_context_entity_name ? (
        <LabelXSmall color={$theme.colors.contentTertiary}>
          Set your initial view context
          <br />
          Enterprise users may change this at any time
        </LabelXSmall>
      ) : null}

      <BeLockedContentOverlay
        isLocked={locked}
        lockSize={'default'}
        detailsDescription={'Unlock competitor views with Enterprise'}
        compactDetails
      >
        {userID || teamID ? (
          <>
            <Block>
              {Object.keys(entityTypeToApi).map((entityTypeOption: EntityType, idx) => (
                <BeRadioButton
                  key={`${entityTypeOption}-${idx}`}
                  index={idx}
                  totalItems={Object.keys(entityTypeToApi).length}
                  kind={'tertiary'}
                  size={'mini'}
                  isSelected={entityType === entityTypeOption}
                  onClick={() => {
                    setEntityType(entityTypeOption);
                    setSelectedOption(null);
                  }}
                >
                  {entityTypeOption}
                </BeRadioButton>
              ))}
            </Block>

            <BeSelect
              options={availableOptions}
              value={selectedOption ? [selectedOption] : []}
              onChange={(e) => {
                setSelectedOption(e.option);
                setQuery('');
              }}
              // Tell BeSelect that this component controls filtering
              isCustomInputFilter
              placeholder={'Search for new context'}
              multi={false}
              disabled={locked}
              searchable={true /* Handled by onInputChange and onInputChangeDebounced */}
              isLoading={searchIsLoading || searchIsValidating}
              noResultsMsg={searchIsLoading || searchIsValidating ? 'Loading results...' : 'No results found'}
              clearable
              onInputChange={onInputChangeDebounced}
              onBlur={() => setQuery('')}
            />

            <BeButton
              size={'compact'}
              disabled={locked || !selectedOption || submitting}
              onClick={submit}
              isLoading={submitting}
            >
              Submit
            </BeButton>
          </>
        ) : (
          <ParagraphMedium>No user ID or team ID provided</ParagraphMedium>
        )}
      </BeLockedContentOverlay>
    </Block>
  );
};

export default ContextSelect;
