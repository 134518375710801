export const colors: any = {
  transparent: '#00000000',

  black: '#000000',
  gray900: '#141414',
  gray800: '#1F1F1F',
  gray700: '#333333',
  gray600: '#545454',
  gray500: '#757575',
  gray400: '#AFAFAF',
  gray300: '#CBCBCB',
  gray200: '#E2E2E2',
  gray100: '#EEEEEE',
  gray50: '#F6F6F6',
  gray10: '#FAFAFA',
  white: '#FFFFFF',

  blueAccent: '#304FFE',
  blue900: '#1A237E',
  blue800: '#283593',
  blue700: '#303F9F',
  blue600: '#3949AB',
  blue500: '#3F51B5',
  blue400: '#5C6BC0',
  blue300: '#7986CB',
  blue200: '#9FA8DA',
  blue100: '#C5CAE9',
  blue50: '#E8EAF6',

  red700: '#5A0A00',
  red600: '#870F00',
  red500: '#AB1300',
  red400: '#E11900',
  red300: '#E85C4A',
  red200: '#F1998E',
  red100: '#FED7D2',
  red50: '#FFEFED',

  green700: '#10462D',
  green600: '#03582F',
  green500: '#03703C',
  green400: '#05944F',
  green300: '#06C167',
  green200: '#66D19E',
  green100: '#ADDEC9',
  green50: '#E6F2ED',

  yellowAccent: '#FFAB0C',
  yellow900: '#FF6B14',
  yellow800: '#FF9012',
  yellow700: '#FE9F0B',
  yellow600: '#FFB404',
  yellow500: '#FFC107',
  yellow400: '#FDCA25',
  yellow300: '#FDD54D',
  yellow200: '#FCE082',
  yellow100: '#FEEDB3',
  yellow50: '#FFF9E1',

  /* Orange is discontinuous */
  orange900: '#FF6B14',
  orange800: '#FF9012',
  orange700: '#FE9F0B',
  orange400: '#FBC399',
  orange100: '#ffdfc1',
};

function getFoundationGroup(name: string, srcName: string) {
  const colorsSuffix = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50];
  return colorsSuffix.reduce((acc: any, suffix) => {
    if (colors[`${srcName}${suffix}`]) {
      acc[`${name}${suffix}`] = colors[`${srcName}${suffix}`];
    }
    return acc;
  }, {});
}

export const foundation = {
  primaryA: colors.black,
  primaryB: colors.white,
  accent: colors.blue500,
  negative: colors.red400,
  warning: colors.yellow500,
  positive: colors.green400,
  contentSecondary: colors.gray600,

  ...getFoundationGroup('accent', 'blue'),
  ...getFoundationGroup('negative', 'red'),
  ...getFoundationGroup('warning', 'yellow'),
  ...getFoundationGroup('positive', 'green'),
};
