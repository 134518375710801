import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { ApiEnum } from '@benefeature/shared-common';
import { defaultSwrFetcher, defaultSwrOnErrorRetry, SWR_DEDUPING_INTERVAL_MS } from '../swrHelpers';

/**
 * Controls how often a refresh will occur in absence of any user activity (in milliseconds).
 * Note that this is effectively a minimum frequency since it will also refresh the version on focus and reconnect as well.
 * Set to approximately every 15 minutes.
 */
const API_VERSION_REFRESH_FREQ_MS = 900000;
// Should generally be null to allow for delineation between an actual value and none having been found
const DEFAULT_API_VERSION = null;

export function useApiVersion(): string {
  // Set a consistent version to prevent rehydration issues
  const [apiVersion, setApiVersion] = useState<string>(DEFAULT_API_VERSION);

  const { data: apiVersionData, isLoading: apiVersionDataIsLoading } = useSWR(
    ApiEnum.VERSION,
    // Basic fetcher for any core resources
    defaultSwrFetcher,
    {
      dedupingInterval: SWR_DEDUPING_INTERVAL_MS,
      shouldRetryOnError: true,
      onErrorRetry: defaultSwrOnErrorRetry,
      refreshInterval: API_VERSION_REFRESH_FREQ_MS,
      refreshWhenHidden: true,
      refreshWhenOffline: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  useEffect(() => {
    if (!apiVersionDataIsLoading) {
      if (apiVersionData && apiVersionData?.version) {
        console.debug('Setting API version to:', apiVersionData.version);
        setApiVersion(apiVersionData.version);
      } else {
        console.debug('Defaulting API version to:', DEFAULT_API_VERSION);
        setApiVersion(DEFAULT_API_VERSION);
      }
    }
  }, [apiVersionData, apiVersionDataIsLoading]);

  return apiVersion;
}
