import { useRouter } from 'next/router';
import { ApiEnum, EntityType, RouteEnum } from '@benefeature/shared-common';
import {
  BaseWebHelpers,
  BeButtonLink,
  BeInputProps,
  BeTooltip,
  UIHelpers,
  useBreakpoint,
} from '@benefeature/shared/ui';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import { Popover } from 'baseui/popover';
import { Block } from 'baseui/block';
import { useStyletron, withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { Input } from 'baseui/input';
import { SearchResponse } from '@benefeature/shared-types';
import useSWR from 'swr';
import dynamic from 'next/dynamic';
import { HeadingXSmall } from 'baseui/typography';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';

/* Prevent hydration mismatches by blocking SSR for the content
 * Also prevents issues with the massive size of the search results component being provided on every page */
const SearchResults = dynamic(() => import('../SearchResults/SearchResults'), {
  ssr: false,
});

const SearchSpinner = withStyle(Spinner, ({ $theme }) => {
  return {
    marginRight: '6px',
    width: '16px',
    height: '16px',
    borderLeftWidth: '3px',
    borderRightWidth: '3px',
    borderTopWidth: '3px',
    borderBottomWidth: '3px',
    borderTopColor: $theme.colors.accent200,
  };
});

type SearchBoxInputProps = Omit<BeInputProps, 'isAccented' | 'overrides'> & {
  isDemo?: boolean;
  smallView?: boolean;
  mediumView?: boolean;
};

const SearchBoxInput = ({
  name,
  value,
  onChange,
  onBlur,
  isDemo,
  smallView,
  mediumView,
  ...props
}: SearchBoxInputProps) => {
  const [, /*css*/ $theme] = useStyletron();
  const backgroundCss = {
    backgroundColor: $theme.colors.backgroundPrimary,
    color: $theme.colors.accent,
  };

  return (
    <Input
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      overrides={{
        Root: {
          style: ({ $theme, $isFocused }: any) => ({
            ...(isDemo ? {} : { height: '40px' }),
            paddingRight: '0px',
            paddingLeft: '0px',
            ...backgroundCss,
            ...BaseWebHelpers.expandBorderStyles({
              borderWidth: '2.5px',
              borderColor: $isFocused ? $theme.colors.accent : $theme.colors['blue50'],
              borderStyle: 'solid',
            }),
            ...BaseWebHelpers.borderRadius('4px'),
          }),
        },
        After: { style: { padding: 0 } },
        Input: {
          style: ({ $theme }: any) => ({
            height: '40px',
            fontWeight: 500,
            paddingTop: isDemo ? '8px' : '3px',
            fontSize: isDemo ? '24px' : '16px',
            lineHeight: '24px',
            paddingLeft: smallView || mediumView ? 0 : null,
            '::placeholder': {
              color: $theme.colors['blue200'],
            },
            ...backgroundCss,
          }),
        },
        StartEnhancer: {
          style: ({ $theme }: any) => ({
            ...backgroundCss,
            width: '35px',
            marginLeft: '16px',
            paddingLeft: 0,
            paddingRight: '5px',
            color: $theme.colors['blue400'],
          }),
        },
        EndEnhancer: {
          style: {
            ...backgroundCss,
            width: '128px',
          },
        },
        MaskToggleButton: {
          style: {
            ...backgroundCss,
          },
        },
        ClearIconContainer: {
          style: {
            ...backgroundCss,
          },
        },
        ClearIcon: {
          style: ({ $theme }) => ({
            width: '18px',
            height: '18px',
            color: $theme.colors.accent,
          }),
        },
      }}
      {...props}
      // Disable autocomplete
      // See https://stackoverflow.com/a/54446170/2738164
      type={'new-password'}
      autoComplete={'off'}
    />
  );
};

export type SearchBoxAutocompleteProps = {
  isDemo?: boolean;
  limit: number;
  debounceDelayMS?: number;
};

const SEARCH_BOX_AUTOCOMPLETE_DEFAULT_DEBOUNCE_DELAY_MS = 400;

export default function SearchBoxAutocomplete({
  isDemo = false,
  limit,
  debounceDelayMS = SEARCH_BOX_AUTOCOMPLETE_DEFAULT_DEBOUNCE_DELAY_MS,
}: SearchBoxAutocompleteProps) {
  const router = useRouter();
  const [, /*css*/ $theme] = useStyletron();
  const [dismissed, setDismissed] = useState(true);
  const [inputRef, setInputFocus, setBlur] = useFocus();
  const [isFindPressed] = useKeyboardJs(['command + y', 'ctrl + y']);
  const [isEscPressed] = useKeyboardJs(['esc']);

  const breakpoint = useBreakpoint();
  const smallView = breakpoint === 'small';
  const mediumView = breakpoint === 'medium';

  const [currentRefinement, setCurrentRefinement] = useState<string>('');
  const [hasSearch, setHasSearch] = useState(currentRefinement != null && currentRefinement !== '');

  // Run the queries on load - display controlled by whether there's actually content
  const { data: employerSearchResults, isLoading: employerSearchIsLoading } = useSWR(
    currentRefinement.length > 0 ? ApiEnum.SEARCH_EMPLOYERS + `?query=${currentRefinement}&limit=${limit}` : null
  );
  const { data: brokerSearchResults, isLoading: brokerSearchIsLoading } = useSWR(
    currentRefinement.length > 0 ? ApiEnum.SEARCH_BROKERS + `?query=${currentRefinement}&limit=${limit}` : null
  );
  const { data: carrierSearchResults, isLoading: carrierSearchIsLoading } = useSWR(
    currentRefinement.length > 0 ? ApiEnum.SEARCH_CARRIERS + `?query=${currentRefinement}&limit=${limit}` : null
  );

  // Set up debounce controls by storing the value of the input separately from the currentRefinement
  const [timerID, setTimerID] = useState(null);
  const [value, setValue] = useState(currentRefinement);
  useEffect(() => {
    if (currentRefinement != null) {
      setValue(currentRefinement);
    }
  }, [currentRefinement]);

  function onChangeDebounced(event) {
    const _interimValue = event.currentTarget.value;
    clearTimeout(timerID);
    setValue(_interimValue);
    setTimerID(
      setTimeout(() => {
        setCurrentRefinement(_interimValue);
      }, debounceDelayMS)
    );
  }

  useEffect(() => {
    // Search input handler for flagging presence of search values and the popover dismissal state
    setHasSearch(currentRefinement != null && currentRefinement !== '');
    setDismissed(false);
  }, [currentRefinement]);

  useEffect(() => {
    // Find key combination press handler
    if (!isDemo && isFindPressed) {
      // todo: add event hook to record user leveraging keyboard shortcuts to understand if anyone cares about them
      setInputFocus();
    }
  }, [isDemo, isFindPressed, setInputFocus]);

  useEffect(() => {
    // Escape key press handler
    if (!isDemo && isEscPressed) {
      setBlur();
      setDismissed(true);
    }
  }, [isDemo, isEscPressed, setBlur]);

  const search = (event, route) => {
    // Important: must preventDefault on the event for router to have a chance to function
    event.preventDefault();
    if (currentRefinement) {
      router.push(route + '?query=' + currentRefinement);
    }
  };

  const [isWindows, setIsWindows] = useState(false);
  const [isMacintosh, setIsMacintosh] = useState(false);
  useEffect(() => {
    setIsWindows(UIHelpers.isWindows());
    setIsMacintosh(UIHelpers.isMacintosh());
  }, []);

  // If any nav event occurs, dismiss the popover
  // It's likely the nav has invalidated any popover content, either due to a click on a search result or another page change
  useEffect(() => {
    setDismissed(true);
  }, [router.asPath]);

  return (
    <Popover
      showArrow
      isOpen={hasSearch && !dismissed}
      onClickOutside={() => setDismissed(true)}
      popoverMargin={8}
      autoFocus={false}
      overrides={{
        Arrow: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors['blue100'],
            height: '20px',
            width: '20px',
          }),
        },
        Body: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors['blue100'],
            minWidth: '375px',
            maxWidth: '100%',
            // Equivalent to z16 elevation
            boxShadow:
              '0px 24px 38px 3px rgba(15, 15, 15, 0.15), 0px 9px 46px 8px rgba(15, 15, 15, 0.1), 0px 12px 16px -8px rgba(15, 15, 15, 0.1)',
          }),
        },
        Inner: {
          style: ({ $theme }) => ({
            ...BaseWebHelpers.margin('4px'),
            ...BaseWebHelpers.padding('8px'),
            backgroundColor: $theme.colors.backgroundPrimary,
            // Radius should match the default popover radius to provide a pseudo border effect
            ...BaseWebHelpers.borderRadius('8px'),
          }),
        },
      }}
      content={
        <Block
          width={
            /* Between 350px and 900px, edging toward the view size until the max */
            'min(max(350px, 75vw), 900px)'
          }
          maxHeight={
            /* Between 300px and 550px, edging toward the view size until the max */
            'min(max(300px, 75vh), 550px)'
          }
          overrides={{ Block: { style: { overflow: 'auto' } } }}
        >
          <Block position={'relative'}>
            <HeadingXSmall
              position={'sticky'}
              top={0}
              display={'flex'}
              alignItems={'center'}
              gridColumnGap={'12px'}
              marginTop={0}
              marginBottom={0}
              overrides={{ Block: { style: { zIndex: 1 } } }}
              backgroundImage={`linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,0) 100%)`}
              paddingBottom={'8px'}
            >
              Employers
              {employerSearchIsLoading ? null : (
                <BeButtonLink
                  kind={'tertiary'}
                  size={'mini'}
                  href={RouteEnum.SEARCH_EMPLOYERS + '?query=' + currentRefinement}
                >
                  Employer Search&nbsp;
                  <LaunchRoundedIcon fontSize={'inherit'} />
                </BeButtonLink>
              )}
              {employerSearchIsLoading ||
              (employerSearchResults as SearchResponse)?.requestStats?.total_count < limit ? null : (
                <span style={{ fontSize: '0.5em', color: $theme.colors.contentTertiary }}>
                  showing top {limit} results
                </span>
              )}
            </HeadingXSmall>

            <SearchResults
              searchIsLoading={employerSearchIsLoading}
              searchResponse={employerSearchResults as SearchResponse}
              searchResultEntityType={EntityType.EMPLOYER}
              isAutocomplete
              hideFavoriteButtons
            />
          </Block>

          <Block
            marginTop={'16px'}
            paddingTop={'12px'}
            overrides={{ Block: { style: { borderTop: `2px solid ${$theme.colors.borderOpaque}` } } }}
            position={'relative'}
          >
            <HeadingXSmall
              position={'sticky'}
              top={0}
              display={'flex'}
              alignItems={'center'}
              gridColumnGap={'12px'}
              marginTop={0}
              marginBottom={0}
              overrides={{ Block: { style: { zIndex: 1 } } }}
              backgroundImage={`linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,0) 100%)`}
              paddingBottom={'8px'}
            >
              Brokers
              {brokerSearchIsLoading ? null : (
                <BeButtonLink
                  kind={'tertiary'}
                  size={'mini'}
                  href={RouteEnum.SEARCH_BROKERS + '?query=' + currentRefinement}
                >
                  Broker Search&nbsp;
                  <LaunchRoundedIcon fontSize={'inherit'} />
                </BeButtonLink>
              )}
              {brokerSearchIsLoading ||
              (brokerSearchResults as SearchResponse)?.requestStats?.total_count < limit ? null : (
                <span style={{ fontSize: '0.5em', color: $theme.colors.contentTertiary }}>
                  showing top {limit} results
                </span>
              )}
            </HeadingXSmall>
            <SearchResults
              searchIsLoading={brokerSearchIsLoading}
              searchResponse={brokerSearchResults as SearchResponse}
              searchResultEntityType={EntityType.BROKER}
              isAutocomplete
              hideFavoriteButtons
            />
          </Block>

          <Block
            marginTop={'16px'}
            paddingTop={'12px'}
            overrides={{ Block: { style: { borderTop: `2px solid ${$theme.colors.borderOpaque}` } } }}
            position={'relative'}
          >
            <HeadingXSmall
              position={'sticky'}
              top={0}
              display={'flex'}
              alignItems={'center'}
              gridColumnGap={'12px'}
              marginTop={0}
              marginBottom={0}
              overrides={{ Block: { style: { zIndex: 1 } } }}
              backgroundImage={`linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,0) 100%)`}
              paddingBottom={'8px'}
            >
              {' '}
              Carriers
              {carrierSearchIsLoading ? null : (
                <BeButtonLink
                  kind={'tertiary'}
                  size={'mini'}
                  href={RouteEnum.SEARCH_CARRIERS + '?query=' + currentRefinement}
                >
                  Carrier Search&nbsp;
                  <LaunchRoundedIcon fontSize={'inherit'} />
                </BeButtonLink>
              )}
              {carrierSearchIsLoading ||
              (carrierSearchResults as SearchResponse)?.requestStats?.total_count < limit ? null : (
                <span style={{ fontSize: '0.5em', color: $theme.colors.contentTertiary }}>
                  showing top {limit} results
                </span>
              )}
            </HeadingXSmall>
            <SearchResults
              searchIsLoading={carrierSearchIsLoading}
              searchResponse={carrierSearchResults as SearchResponse}
              searchResultEntityType={EntityType.CARRIER}
              isAutocomplete
              hideFavoriteButtons
            />
          </Block>

          <Block
            overrides={{ Block: { style: { zIndex: 2 } } }}
            position={'fixed'}
            top={'8px'}
            right={smallView ? '12px' : '28px'}
          >
            <BeButtonLink kind={'tertiary'} size={'compact'} onClick={() => setDismissed(true)}>
              Close
            </BeButtonLink>
          </Block>
        </Block>
      }
    >
      <form
        style={{ maxWidth: isDemo ? '100%' : null }}
        onSubmit={(e) =>
          search(
            e,
            /* Default to searching employers if form submitted */
            RouteEnum.SEARCH_EMPLOYERS
          )
        }
      >
        <SearchBoxInput
          inputRef={inputRef}
          /* Note: don't use onBlur to clear input since it triggers as user selects a result */
          isDemo={isDemo}
          size={'compact'}
          name={'autocomplete-search'}
          value={value}
          placeholder={
            isDemo
              ? breakpoint === 'xlarge'
                ? 'Search employers, brokers, or carriers'
                : 'Try quick search'
              : smallView || mediumView
              ? 'Search'
              : 'Search here'
          }
          onFocus={() => hasSearch && setDismissed(false)}
          startEnhancer={() => (
            <SearchRoundedIcon
              fontSize={'small'}
              onClick={(e) =>
                search(
                  e,
                  /* Default to searching employers if search icon clicked */
                  RouteEnum.SEARCH_EMPLOYERS
                )
              }
            />
          )}
          {...(!smallView &&
            !mediumView && {
              endEnhancer: () => (
                <>
                  {employerSearchIsLoading || brokerSearchIsLoading || carrierSearchIsLoading ? (
                    <SearchSpinner />
                  ) : null}
                  {currentRefinement && (
                    <ClearRoundedIcon
                      fontSize={'small'}
                      style={{ color: $theme.colors.accent200 }}
                      onClick={() => setCurrentRefinement('')}
                    />
                  )}
                  {!isDemo && (isWindows || isMacintosh) && (
                    <BeTooltip
                      content={`Press ${(isMacintosh ? '⌘' : 'Ctrl') + '+Y'} to focus the search bar`}
                      onMouseEnterDelay={1000}
                    >
                      <span
                        style={{
                          width: '60px',
                          color: $theme.colors.accent200,
                          paddingLeft: '10px',
                          marginLeft: '10px',
                          fontWeight: 500,
                          borderLeft: '1px solid rgb(14, 38, 115, 0.1)',
                          userSelect: 'none',
                        }}
                      >
                        {(isMacintosh ? '⌘' : 'Ctrl') + '+Y'}
                      </span>
                    </BeTooltip>
                  )}
                </>
              ),
            })}
          onChange={onChangeDebounced}
        />
      </form>
    </Popover>
  );
}

function useFocus(): [any, () => void, () => void] {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };
  const blurFocus = () => {
    htmlElRef.current && htmlElRef.current.blur();
  };
  return [htmlElRef, setFocus, blurFocus];
}
