import { ReactNode, useEffect, useState } from 'react';
import { RouteEnum } from '@benefeature/shared-common';
import { useRouter } from 'next/router';
import { UserRole } from '@benefeature/shared-types';
import { meetsSomeRole } from '../core/services/user-state.service';
import { useSession } from 'next-auth/react';
import { HeadingMedium } from 'baseui/typography';
import { Block } from 'baseui/block';
import { BePageOverlayMessage, UIHelpers } from '@benefeature/shared/ui';

export type ProtectedPageProps = {
  children: ReactNode | ReactNode[] | Element | Element[];
  rolesWithAccess?: UserRole[];
  fallbackUrl?: string;
  fallbackComponent?: ReactNode;
};

/**
 * Protects a route by forcing a redirect if conditions aren't met.
 *
 * @param children
 * @param rolesWithAccess The roles which should be met.  Roles are defined as combinations of permissions and subscriptions.
 *                        Multiple different roles may be provided in an array, and if any are met by the user, access is allowed.
 * @param fallbackUrl Where the redirect takes the user upon failure to validate any role.
 * @param fallbackComponent A default component to display if access isn't allowed and a redirect isn't performed.
 * @constructor
 */
export const ProtectedPage = ({ children, rolesWithAccess, fallbackUrl, fallbackComponent }: ProtectedPageProps) => {
  const router = useRouter();

  // It's very important to mark this session as required (BFR-1275)
  // If a session isn't provided, this will force it to redirect back to login with an appropriate message
  // If this isn't marked as required, a bunch of async components will be rendered
  // and some of them may actually have behaviors which will trigger further redirects, have their own useSession calls, etc
  // Let this retrieval finish before having ANY of that happen or things can get crazy with the session storage
  const { data: session, status: sessionStatus } = useSession({ required: true });

  // Stateful flag, prevents render of children before the useEffect hook has had a chance to check permissions
  // Prevents intermediary page state from rendering, including odd "flashes"
  const [permissionsChecked, setPermissionsChecked] = useState(false);

  // Define a flag which indicates status on the page in case permissions are checked but no redirect occurs
  const [hasPermissions, setHasPermissions] = useState(false);

  const [useFallbackUrl, setUseFallbackUrl] = useState(fallbackUrl || '');
  useEffect(() => {
    // Set a default fallback URL based on authentication status
    // This is generally the login page with a redirect to the current path,
    // but if there is already an active session/user the fallback should instead be to any always-accessible path (BFR-671)
    if (!fallbackUrl) {
      if (session && session?.user?.id) {
        // Redirecting to login would likely result in a loop, push to dashboard instead
        setUseFallbackUrl(RouteEnum.DASHBOARDS);
      } else {
        // Redirecting to login seems okay
        setUseFallbackUrl(`${RouteEnum.AUTH_LOGIN}?redirect=${encodeURIComponent(router.asPath)}`);
      }
    }
  }, [session, fallbackUrl, router.asPath]);

  useEffect(() => {
    // If the fallback URL is the same as the current path, set a safety of going back to the completely open home page
    if (router.asPath === useFallbackUrl) {
      console.debug(
        ProtectedPage.name,
        'useFallbackUrl',
        useFallbackUrl,
        'is the same as the current path, defaulting to',
        RouteEnum.HOME
      );
      setUseFallbackUrl(RouteEnum.HOME);
    }
  }, [useFallbackUrl, router.asPath]);

  useEffect(() => {
    let _tmpHasPermissions = true;

    // Exclude checks already on the fallback URL
    if (UIHelpers.toBasePath(useFallbackUrl) !== UIHelpers.toBasePath(router.asPath)) {
      // If not authenticated, redirect the user to either the fallback URL or login if no fallback URL is present
      if (sessionStatus !== 'loading' && !session?.user && !fallbackComponent) {
        console.debug(ProtectedPage.name, 'found no authenticated user. Redirecting to fallback URL:', useFallbackUrl);
        router.push(useFallbackUrl);
        _tmpHasPermissions = false;
      }

      // Verify the user has a set of permissions and/or subscriptions as defined by the role
      // If the user has any role with access, allow
      if (sessionStatus !== 'loading' && rolesWithAccess && !!rolesWithAccess.length) {
        if (!meetsSomeRole(rolesWithAccess, session)) {
          console.debug(
            ProtectedPage.name,
            'found authentication did not meet any roles with access. Redirecting to fallback URL:',
            useFallbackUrl
          );
          router.push(useFallbackUrl);
          _tmpHasPermissions = false;
        }
      }

      // Set hasPermissions
      setHasPermissions(_tmpHasPermissions);
    }

    if (sessionStatus !== 'loading') {
      // Only set the checked flag if the session is done loading since all checks depend on that
      setPermissionsChecked(true);
    }
  }, [session, sessionStatus, rolesWithAccess, fallbackComponent, useFallbackUrl, router]);

  if (sessionStatus !== 'loading' && router.isReady && permissionsChecked && !hasPermissions) {
    /* Render fallbacks or access not allowed */
    return fallbackComponent ? (
      <>{fallbackComponent}</>
    ) : (
      <Block
        width={'100%'}
        height={'100%'}
        display={'flex'}
        alignContent={'center'}
        justifyContent={'center'}
        paddingTop={'80px'}
      >
        <HeadingMedium>Access not allowed</HeadingMedium>
      </Block>
    );
  } else if (sessionStatus === 'loading') {
    return <BePageOverlayMessage enabled={true} message={'Authenticating'} />;
  } else if (permissionsChecked) {
    /* Render protected children */
    return <>{children}</>;
  } else {
    /* Render null, not checked and not failed yet */
    return null;
  }
};
