import { withStyle } from 'baseui';
import { Spinner } from 'baseui/spinner';

/**
 * Low-level styled spinner access with standard overrides provided of key attributes.
 *
 * Generally {@see BeSpinnerMessage} for a complete implementation with appropriate styling and scaling.
 */
export const BeSpinner = withStyle(Spinner, ({ $theme, $size, $color, $borderWidth }) => {
  return {
    ...($size
      ? {
          width: `${$size}`,
          height: `${$size}`,
          lineHeight: `${$size}`,
        }
      : {}),
    ...($borderWidth
      ? {
          borderLeftWidth: `${$borderWidth}`,
          borderRightWidth: `${$borderWidth}`,
          borderTopWidth: `${$borderWidth}`,
          borderBottomWidth: `${$borderWidth}`,
        }
      : {}),
    ...($color
      ? {
          borderTopColor: $color,
        }
      : {}),
  };
});
