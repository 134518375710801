var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"eNENNiqIeIS9YWCTFnC4n"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init } from '@sentry/nextjs';
import { replayCanvasIntegration, browserTracingIntegration } from '@sentry/browser';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

init({
  dsn: SENTRY_DSN || 'https://df1ba43ca5754f1ea51aba2472c53daa@o126541.ingest.sentry.io/6605563',

  // This enables automatic instrumentation (highly recommended), but is not
  // necessary for purely manual usage
  integrations: [
    browserTracingIntegration(),
    replayCanvasIntegration(),
    // replayIntegration({
    //   // Don't disable details which might be help triage client
    //   maskAllText: false,
    //   maskAllInputs: false,
    //   blockAllMedia: false,
    // }),
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production' ? 0.1 : 0.5,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production' ? 0.1 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  environment: process.env.NEXT_PUBLIC_RUNTIME_ENV,

  autoSessionTracking: true,

  // Set maxValueLength to a length which will generally allow for any URL or config to be completely visible
  maxValueLength: 2000,

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
