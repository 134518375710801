export const useDebugAttrs = (rootName?: string) => {
  const isDebug = !!process.env.NEXT_PUBLIC_IS_DEBUG;
  return [
    (sectionName: string): Record<string, string> => {
      let wrappedProps: any = {};
      if (isDebug) {
        wrappedProps = { 'data-baseweb': `${rootName}-${sectionName}` };
      }
      return wrappedProps;
    },
  ];
};
