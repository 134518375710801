import { ReactNode, forwardRef, PropsWithoutRef, RefAttributes } from 'react';
import { Button, ButtonOverrides, ButtonProps, KIND } from 'baseui/button';
import { mergeOverrides, styled, useStyletron } from 'baseui';
import { getBaseStyles } from './helper/button';
import { colors } from '../themes/MainTheme/colors';
import { BaseWebHelpers, optionalLinkWrap } from '../helpers';

export enum BUTTON_VARIANTS {
  'default' = 'default',
  'destructive' = 'destructive',
  'positive' = 'positive',
  'warning' = 'warning',
}

export type BeButtonProps = PropsWithoutRef<ButtonProps> &
  RefAttributes<HTMLButtonElement> & {
    fullWidth?: boolean;
    variant?: keyof typeof BUTTON_VARIANTS;
    enhancerOnly?: boolean;
    href?: string;
    target?: string;
    style?: any;
  };

export const BeButton = forwardRef<any, BeButtonProps>(
  ({ fullWidth, variant, overrides, enhancerOnly, ...props }: BeButtonProps, ref) => {
    const style = { ...(props.style || {}), ...(fullWidth ? { width: '100%' } : {}) };
    const startEnhancerStyle = enhancerOnly && props.startEnhancer ? { style: { marginRight: 0 } } : {};
    const endEnhancerStyle = enhancerOnly && props.endEnhancer ? { style: { marginLeft: 0 } } : {};

    if (enhancerOnly) {
      props['children'] = '';
    }

    return (
      <Button
        overrides={mergeOverrides(
          mergeOverrides(
            {
              BaseButton: {
                style: ({ $theme, $kind, $isSelected, $shape, $size, $isLoading, $disabled }) => ({
                  // Gather the base styles, factoring in the variant
                  ...getBaseStyles({
                    $theme,
                    $size,
                    $kind,
                    $shape,
                    $isLoading,
                    $isSelected,
                    $disabled,
                    variant,
                  }),

                  ...style,
                }),
              },
              StartEnhancer: { ...startEnhancerStyle },
              EndEnhancer: { ...endEnhancerStyle },
            },
            overrides as any
          ),
          {
            // Merge in the colors if provided, including overriding the hover behaviors
            ...(props.colors?.color || props.colors?.backgroundColor
              ? {
                  BaseButton: {
                    style: {
                      ...(props.colors?.color ? { color: props.colors.color } : {}),
                      ...(props.colors?.backgroundColor ? { backgroundColor: props.colors.backgroundColor } : {}),
                      ':hover': {
                        ...(props.colors?.color ? { color: props.colors.color } : {}),
                        ...(props.colors?.backgroundColor ? { backgroundColor: props.colors.backgroundColor } : {}),
                      },
                    },
                  },
                }
              : {}),
          }
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

export type BeButtonLinkProps = BeButtonProps & { prefetch?: boolean };

export const BeButtonLink = forwardRef<any, BeButtonLinkProps>((props: BeButtonLinkProps, ref) => {
  const { href, prefetch, target, ...otherProps } = props;
  return optionalLinkWrap(<BeButton ref={ref} {...otherProps} />, href, null, null, prefetch, target);
});

export type BeSocialButtonProps = BeButtonProps & {
  icon?: ReactNode;
};

export const BeSocialButton = ({ icon, ...props }: BeSocialButtonProps) => {
  const [css, $theme] = useStyletron();

  return (
    <BeButtonLink
      overrides={{
        BaseButton: {
          style: {
            display: 'flex',
            fontSize: '16px',
            color: '#212121',
            fontWeight: 500,
            backgroundColor: `${colors.gray100}`,
            ...BaseWebHelpers.padding('4px'),
            ...BaseWebHelpers.margin($theme.sizing.scale100),
            ':hover': {
              color: '#5086EC',
            },
            ':first-of-type': {
              marginLeft: '0 !important',
            },
          },
        },
      }}
      startEnhancer={icon}
      kind={KIND.tertiary}
      {...props}
    />
  );
};

const StyledFeaturedButton = styled(BeButtonLink, ({ $theme }) => ({
  marginLeft: '12px',
  color: `${($theme.colors as any).yellow900} !important`,
  textDecoration: 'underline',
  fontSize: '52px',
  lineHeight: '52px',
  fontWeight: 500,
  background: `${$theme.colors.backgroundPrimary} !important`,
  border: `3px solid rgba(255, 255, 255, 0)`,
  userSelect: 'none',
  ':hover': {
    // Prevent normal button hover changes
    fontWeight: 500,
    border: `3px solid ${($theme.colors as any).yellow900}`,
  },
}));

export const BeFeaturedButton = ({ ...props }) => {
  return <StyledFeaturedButton {...props} />;
};

export type BeRadioButtonProps = BeButtonProps & {
  index: number;
  totalItems: number;
};

export const BeRadioButton = forwardRef<any, BeRadioButtonProps>(
  ({ index, totalItems, overrides, ...props }: BeRadioButtonProps, ref) => {
    const [css, $theme] = useStyletron();
    const _radioButtonOverrides: ButtonOverrides = {
      BaseButton: {
        style: {
          /* Box shadow correlates with Elevation Minimal */
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
          /* Make contiguous between buttons */
          ...BaseWebHelpers.borderRadius(
            index === 0 ? '6px' : '0',
            index === totalItems - 1 ? '6px' : '0',
            index === 0 ? '6px' : '0',
            index === totalItems - 1 ? '6px' : '0'
          ),
          /* Special treatment for tertiary coloring */
          ...(props.kind === 'tertiary' ? { color: $theme.colors.borderAccent } : {}),
          /* If selected, make it bolder and add a border override */
          ...(props.isSelected
            ? {
                fontWeight: '600',
                ...BaseWebHelpers.expandBorderStyles({
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: $theme.colors.buttonPrimaryHover,
                }),
              }
            : BaseWebHelpers.expandBorderStyles({
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'rgba(255, 255, 255, 0)',
              })),
        },
      },
    };
    return <BeButtonLink overrides={mergeOverrides(_radioButtonOverrides as any, overrides as any)} {...props} />;
  }
);
