import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import { useStyletron } from 'baseui';
import {
  BaseWebHelpers,
  BeButton,
  BeCard,
  FormatHelpers,
  ResponsiveDisplayMedium,
  ResponsiveDisplayXSmall,
  ResponsiveHeadingLarge,
  ResponsiveHeadingSmall,
} from '@benefeature/shared/ui';
import { HeadingSmall, LabelMedium, LabelSmall, ParagraphLarge } from 'baseui/typography';
import { ContactEnum, newHash } from '@benefeature/shared-common';
import { Block } from 'baseui/block';
import * as React from 'react';
import { useState } from 'react';
import { Checkbox } from 'baseui/checkbox';
import SignUpButton from '../Buttons/SignUpButton';
import { PRO_PRICE_TIERS } from './ProPricingHelper';

export interface SubscriptionDetails {
  title: string;
  description: string;
  icon: any;
  asLowAsPrice: boolean;
  pricePerUserPerMonth: number;
  pricePerUserPerYear: number;
  pricePerUserPerMonthNote?: string;
  pricePerUserPerYearNote?: string;
  monthlyPriceId: string;
  yearlyPriceId: string;
  boldedFeatures: string[];
  features: string[];
  firstColor: string;
  midColor: string;
  lastColor: string;
  arcColor: string;
}

export const subscriptionOptions: SubscriptionDetails[] = [
  // {
  //   title: 'Basic',
  //   description: 'Core summaries and demo profiles',
  //   icon: EmojiObjectsOutlinedIcon,
  //   asLowAsPrice: false,
  //   pricePerUserPerMonth: 0,
  //   monthlyPriceId: null,
  //   yearlyPriceId: null,
  //   boldedFeatures: [],
  //   features: [
  //     'Summary details for employers, brokers, and carriers',
  //     'Basic search queries',
  //     '3 full demo profiles including profile filtering',
  //     'PDF profile export for employer demo',
  //     'Book of business exports for broker and carrier demos',
  //   ],
  //   firstColor: 'green300',
  //   midColor: 'green50',
  //   lastColor: 'green100',
  //   arcColor: 'backgroundPrimary',
  // },
  {
    title: 'Pro',
    description: 'Broad access and powerful insights for your team',
    icon: GroupsOutlinedIcon,
    asLowAsPrice: true,
    pricePerUserPerMonth: Math.min(...PRO_PRICE_TIERS.map((s) => s.perUserPriceMonthly)),
    pricePerUserPerYear: Math.min(...PRO_PRICE_TIERS.map((s) => s.perUserPriceAnnually)),
    pricePerUserPerMonthNote: `Volume pricing from ${FormatHelpers.numberToCompactCurrency(
      Math.min(...PRO_PRICE_TIERS.map((s) => s.perUserPriceMonthly))
    )} to ${FormatHelpers.numberToCompactCurrency(
      Math.max(...PRO_PRICE_TIERS.map((s) => s.perUserPriceMonthly))
    )} per user month\nRequires paid annual commitment`,
    pricePerUserPerYearNote: `Volume pricing from ${FormatHelpers.numberToCompactCurrency(
      Math.min(...PRO_PRICE_TIERS.map((s) => s.perUserPriceAnnually))
    )} to ${FormatHelpers.numberToCompactCurrency(
      Math.max(...PRO_PRICE_TIERS.map((s) => s.perUserPriceAnnually))
    )} per user year`,
    monthlyPriceId: null,
    yearlyPriceId: process.env.NEXT_PUBLIC_PRO_ANNUAL_PRICE_ID,
    boldedFeatures: [],
    features: [
      'Entire country and all markets',
      'Exclusive insights based on your carrier or broker',
      'Advanced search features',
      'Prospecting and profile exports',
      'Expert support through chat and email',
      'Team management tools',
    ],
    firstColor: 'orange900',
    midColor: 'orange100',
    lastColor: 'orange400',
    arcColor: 'backgroundPrimary',
  },
  {
    title: 'Enterprise',
    description: 'Access to comprehensive data exports, history, APIs, CRMs, and much more',
    icon: BusinessRoundedIcon,
    asLowAsPrice: false,
    pricePerUserPerMonth: null,
    pricePerUserPerYear: null,
    pricePerUserPerMonthNote: "tailored solutions for your organization's needs",
    monthlyPriceId: null,
    yearlyPriceId: process.env.NEXT_PUBLIC_ENTERPRISE_ANNUAL_PRICE_ID,
    boldedFeatures: ['All Pro features'],
    features: [
      'Unlimited data exports',
      'Your sales territories and offices',
      'Tailored team sizes',
      'API and CRM integrations',
      'Expanded data options available',
    ],
    firstColor: 'blue700',
    midColor: 'blue50',
    lastColor: 'blue100',
    arcColor: 'backgroundPrimary',
  },
];

export const getSubscriptionOption = (subscriptionTitle: string): SubscriptionDetails => {
  return subscriptionOptions.find((opt) => opt.title.toLowerCase() === subscriptionTitle?.toLowerCase());
};

export type SubscriptionDetailsProps = {
  subscription: SubscriptionDetails;
  isShort?: boolean;
  isNarrow?: boolean;
  isMini?: boolean;
};

export const SUBSCRIPTION_CARD_IS_MINI_WIDTH_PX = 275;

export const SubscriptionCard = ({ subscription, isShort, isNarrow, isMini }: SubscriptionDetailsProps) => {
  const [, /*css*/ theme] = useStyletron();
  const [pricePerYear, setPricePerYear] = useState(true);

  const mailToLink = `mailto:${ContactEnum.HELLO_EMAIL}?subject=Benefeature: ${
    subscription.pricePerUserPerMonth > 0 ? 'sign up request' : 'request for more information'
  }`;

  const backgroundValue = `linear-gradient(45deg,
                  ${theme.colors[subscription.firstColor]} 0%,
                  ${theme.colors[subscription.midColor]} 50%,
                  ${theme.colors[subscription.lastColor]} 75%)`;
  const arcColor = theme.colors[subscription.arcColor];
  const useWidth = isMini ? SUBSCRIPTION_CARD_IS_MINI_WIDTH_PX : isNarrow ? 350 : 425;
  const useHeight = isMini ? 135 : isShort ? 430 : 770;

  const TitleComponent = isMini ? ResponsiveDisplayXSmall : ResponsiveDisplayMedium;

  return (
    <BeCard
      {...(isMini ? {} : { hoverHeight: 'z16' })}
      overrides={{
        Root: {
          style: {
            width: `${useWidth}px`,
            height: `${useHeight}px`,
            backgroundImage: backgroundValue,
            ...BaseWebHelpers.expandBorderStyles({
              borderWidth: '1px',
              borderColor: theme.colors.borderOpaque,
              borderStyle: 'solid',
            }),
            ...BaseWebHelpers.borderRadius('10px'),
          },
        },
      }}
    >
      <Block display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
        <TitleComponent marginTop={0} marginBottom={0}>
          <Block display={'flex'} alignItems={'center'} gridColumnGap={'12px'} justifyContent={'space-between'}>
            {subscription.title}
            <subscription.icon fontSize={isMini ? 'medium' : 'large'} />
          </Block>
        </TitleComponent>
        <LabelMedium marginTop={0} marginBottom={0} color={theme.colors.contentSecondary}>
          {subscription.description}
        </LabelMedium>
      </Block>

      {isMini || isShort ? null : (
        <Block marginTop={'40px'}>
          {subscription.boldedFeatures.length > 0
            ? subscription.boldedFeatures.map((feat) => (
                <HeadingSmall key={`home-subscription-${subscription.title}-bf-${newHash(null, feat)}`}>
                  {feat}
                </HeadingSmall>
              ))
            : null}
          {subscription.features.length > 0
            ? subscription.features.map((feat) => (
                <ParagraphLarge key={`home-subscription-${subscription.title}-f-${newHash(null, feat)}`}>
                  {feat}
                </ParagraphLarge>
              ))
            : null}
        </Block>
      )}

      {isMini ? null : (
        <>
          <Block position={'absolute'} bottom={0} left={0} height={'300px'}>
            <svg width={useWidth} height="80" xmlns="http://www.w3.org/2000/svg">
              <path
                d={`M -10 80 C ${useWidth / 3} 0, ${(useWidth * 2) / 3} 0, ${useWidth + 10} 80`}
                stroke={arcColor}
                strokeWidth="4px"
                fill="transparent"
              />
            </svg>
          </Block>

          <Block position={'absolute'} bottom={0} height={'300px'} paddingTop={'50px'} width={`${useWidth - 50}px`}>
            <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
              {subscription.pricePerUserPerMonth === null ? (
                <ResponsiveHeadingLarge
                  maxWidth={`${useWidth - 100}px`}
                  overrides={{ Block: { style: { textAlign: 'center' } } }}
                >
                  Request pricing
                  {subscription.pricePerUserPerMonthNote ? (
                    <LabelSmall color={theme.colors.contentSecondary}>
                      {subscription.pricePerUserPerMonthNote}
                    </LabelSmall>
                  ) : null}
                </ResponsiveHeadingLarge>
              ) : subscription.pricePerUserPerMonth > 0 ? (
                <>
                  <LabelSmall color={theme.colors.contentSecondary}>
                    {subscription.asLowAsPrice ? 'as low as' : 'only'}
                  </LabelSmall>
                  <ResponsiveDisplayMedium marginBottom={0}>
                    {FormatHelpers.numberToCurrency(
                      pricePerYear ? subscription.pricePerUserPerYear : subscription.pricePerUserPerMonth,
                      {
                        maximumFractionDigits: 0,
                      }
                    )}
                  </ResponsiveDisplayMedium>
                  <ResponsiveHeadingSmall marginTop={0} marginBottom={0}>
                    per user
                    {pricePerYear ? ' year' : ' month'}
                  </ResponsiveHeadingSmall>
                  <ResponsiveHeadingSmall marginTop={'6px'} marginBottom={0}>
                    <Block
                      overrides={{ Block: { style: { cursor: 'pointer', userSelect: 'none' } } }}
                      display={'flex'}
                      alignItems={'center'}
                      onClick={() => setPricePerYear(!pricePerYear)}
                    >
                      <Checkbox
                        overrides={{
                          Toggle: {
                            style: ({ $checked, $theme }) => ({
                              backgroundColor: $checked
                                ? $theme.colors.contentAccent
                                : $theme.colors.backgroundSecondary,
                            }),
                          },
                        }}
                        checkmarkType={'toggle'}
                        checked={!pricePerYear}
                        labelPlacement={'left'}
                      >
                        <span style={{ color: theme.colors.contentSecondary }}>
                          Show {pricePerYear ? 'annual' : 'monthly'} cost
                        </span>
                      </Checkbox>
                    </Block>
                  </ResponsiveHeadingSmall>
                  {subscription.pricePerUserPerMonthNote && !pricePerYear ? (
                    <LabelSmall color={theme.colors.contentSecondary} style={{ textAlign: 'center' }}>
                      {subscription.pricePerUserPerMonthNote}
                    </LabelSmall>
                  ) : null}
                  {subscription.pricePerUserPerYearNote && pricePerYear ? (
                    <LabelSmall color={theme.colors.contentSecondary}>
                      {subscription.pricePerUserPerYearNote}
                    </LabelSmall>
                  ) : null}
                </>
              ) : (
                <ResponsiveHeadingLarge
                  maxWidth={`${useWidth - 100}px`}
                  overrides={{ Block: { style: { textAlign: 'center' } } }}
                >
                  Free
                  <LabelSmall color={theme.colors.contentSecondary}>for evaluation purposes</LabelSmall>
                </ResponsiveHeadingLarge>
              )}
            </Block>
          </Block>

          <Block position={'absolute'} bottom={'20px'} width={`${useWidth - 50}px`}>
            <Block display={'flex'} flexDirection={'column'} alignItems={'center'}>
              {subscription.pricePerUserPerMonth === null ? (
                <BeButton
                  kind={'tertiary'}
                  onClick={(event) => {
                    // Special onclick handler to open the mailto in a new tab/window if possible
                    window.open(mailToLink, 'mail');
                    event.preventDefault();
                  }}
                  href={mailToLink}
                >
                  Contact
                </BeButton>
              ) : (
                <SignUpButton priceID={subscription.yearlyPriceId} />
              )}
            </Block>
          </Block>
        </>
      )}
    </BeCard>
  );
};
