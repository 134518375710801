import { useNProgress } from '@tanem/react-nprogress';
import { useStyletron } from 'baseui';
import { Block } from 'baseui/block';
import { useEffect, useState } from 'react';
import { DisplayMedium, LabelMedium } from 'baseui/typography';
import { FormatHelpers } from '../helpers';
import { useRouter } from 'next/router';
import { useDebugAttrs } from '../hooks';
import * as React from 'react';
import Image from 'next/image';

const DEBOUNCE_BLUR_OVERLAY = 3000;

export const BeLoadingPageOverlay = () => {
  const [section] = useDebugAttrs(BeLoadingPageOverlay.name);
  const router = useRouter();

  // Establish logic for showing a progress bar on any page navigation
  const [navState, setNavState] = useState({
    isRouteChanging: false,
    loadingKey: 0,
  });

  // Set a timer with debounce for navigation delay of the blur overlay
  const [showBlurOverlay, setShowBlurOverlay] = useState(false);
  const [blurOverlayTimerID, setBlurOverlayTimerID] = useState(null);

  useEffect(() => {
    const startBlurOverlayTimer = () => {
      clearTimeout(blurOverlayTimerID);
      setBlurOverlayTimerID(
        setTimeout(() => {
          setShowBlurOverlay(true);
        }, DEBOUNCE_BLUR_OVERLAY)
      );
    };

    const handleRouteChangeStart = () => {
      setNavState((prevState) => ({
        ...prevState,
        isRouteChanging: true,
        loadingKey: prevState.loadingKey ^ 1,
      }));

      // Reset the overlay boolean
      setShowBlurOverlay(false);

      // Start the blur overlay timer
      startBlurOverlayTimer();
    };

    const handleRouteChangeEnd = () => {
      setNavState((prevState) => ({
        ...prevState,
        isRouteChanging: false,
      }));

      // Reset the overlay boolean
      setShowBlurOverlay(false);

      // Clear the timeout if route change completed
      clearTimeout(blurOverlayTimerID);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeEnd);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);

      // Reset the overlay boolean
      setShowBlurOverlay(false);

      // Clear the timeout if destroying the object
      clearTimeout(blurOverlayTimerID);
    };
  }, [blurOverlayTimerID, router.events]);

  const [, theme] = useStyletron();
  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: navState.isRouteChanging,
  });

  return isFinished ? null : (
    <Block {...section('root')} position={'absolute'} top={0} bottom={0} left={0} right={0}>
      <style {...section('styles')} /* NOTE: don't apply jsx flag here, will prevent injection of the style block */>{`
        .container {
          width: 100%;
          opacity: ${isFinished ? 0 : 1};
          pointer-events: none;
          transition: opacity ${animationDuration}ms linear;
        }

        .bar {
          background: ${theme.colors.borderAccent};
          height: 8px;
          left: 0;
          margin-left: ${(-1 + progress) * 100}%;
          position: fixed;
          top: 0;
          transition: margin-left ${animationDuration}ms linear;
          width: 100%;
          z-index: 1031;
        }

        .spinner {
          box-shadow: 0 0 10px ${theme.colors.borderAccent}, 0 0 5px ${theme.colors.borderAccent};
          display: block;
          height: 100%;
          opacity: 1;
          position: absolute;
          right: 0;
          transform: rotate(3deg) translate(0px, -4px);
          width: 100px;
        }

        @keyframes shimmer {
          100% {
            -webkit-mask-position: left;
          }
        }

        .shimmer-block {
          -webkit-mask: linear-gradient(-60deg, #000000 0%, #000 30%, #0005, #000 70%, #000000 100%) right/300% 100%;
          background-repeat: no-repeat;
          animation: shimmer 2.5s infinite;
        }
      `}</style>

      <div className="container" {...section('progress-bar-container')}>
        <div className="bar" {...section('progress-bar')}>
          <div className="spinner" {...section('progress-bar-spinner')} />
        </div>
      </div>

      {showBlurOverlay ? (
        <Block {...section('overlay')} position={'absolute'} top={0} bottom={0} left={0} right={0}>
          <Block
            position={'fixed'}
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor={`transparent`}
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            overrides={{
              Block: {
                style: {
                  pointerEvents: 'none',
                  backdropFilter: 'blur(2px)',
                  '-webkit-filter': 'blur(2px)',
                },
              },
            }}
          />

          <Block
            position={'fixed'}
            top={'50vh'}
            width={'100%'}
            overrides={{ Block: { style: { transform: 'translateY(-50%)', textAlign: 'center' } } }}
          >
            <Block
              paddingLeft={'40px'}
              paddingTop={'60px'}
              paddingRight={'40px'}
              paddingBottom={'60px'}
              marginLeft={'auto'}
              marginRight={'auto'}
              overrides={{
                Block: {
                  style: {
                    userSelect: 'none',
                    background: `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%)`,
                  },
                },
              }}
            >
              <Block className={'shimmer-block'}>
                <Image
                  width={100}
                  height={100}
                  alt={'Benefeature logo'}
                  src={
                    /* Can't use ikImg here */ `${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/benefeature/logo-icon.svg`
                  }
                  placeholder={'blur'}
                  blurDataURL={`${process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT}/tr:w-10,h-10/benefeature/logo-icon.svg`}
                />
                <DisplayMedium>Loading</DisplayMedium>
                <LabelMedium color={theme.colors.contentTertiary}>{FormatHelpers.toPercent(progress)}</LabelMedium>
              </Block>
            </Block>
          </Block>
        </Block>
      ) : null}
    </Block>
  );
};
