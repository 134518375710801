import Head from 'next/head';
import '../styles/app.scss';
import { useRouter } from 'next/router';
import { StrictMode, useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as StyletronProvider } from 'styletron-react';
import { styletron } from '../styletron';
import { BaseProvider } from 'baseui';
import {
  MainTheme,
  store,
  UIHelpers,
  useHubspot,
  BeLoadingPageOverlay,
  useGoogleAnalytics,
  // useApiVersion,
} from '@benefeature/shared/ui';
import { SWRConfig } from 'swr';
import { DEFAULT_META_IMAGE, ikImg } from '../core/factories/image.factory';
import { SessionProvider } from 'next-auth/react';
import { useApiVersion } from '../core/clientHooks/useApiVersion';
import {
  defaultSwrFetcher,
  defaultSwrOnErrorRetry,
  SWR_DEDUPING_INTERVAL_MS,
  SWR_LOADING_TIMEOUT_MS,
} from '../core/swrHelpers';
import { ProtectedDefaultLayout } from '../components/Layouts/ProtectedDefaultLayout';

const USER_REFRESH_FREQUENCY_SECONDS = 60;
const Y_ASCII_VALUE = 89;

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();

  // Set up the canonical link path (BFR-43)
  const canonicalPath = process.env.NEXT_PUBLIC_CANONICAL_DOMAIN + UIHelpers.toBasePath(router.asPath);

  useEffect(() => {
    // Prevent default CTRL+Y or ⌘+Y since it'll be intercepted for the search bar (BFR-160)
    window.addEventListener('keydown', function (e) {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === Y_ASCII_VALUE) {
        e.preventDefault();
      }
    });
  });

  const PageLayout = Component.Layout || ProtectedDefaultLayout;

  // Load Hubspot conditionally and detect whether it's available
  const hubspotAvailable = useHubspot(router.pathname.endsWith('/print'));
  useEffect(() => {
    console.debug('Hubspot available:', hubspotAvailable);
  }, [hubspotAvailable]);

  // Load Google Analytics
  useGoogleAnalytics(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID);

  // Hook the API version to understand when a new version of the app is deployed (BFR-1115)
  const apiVersion = useApiVersion();
  const [prevApiVersion, setPrevApiVersion] = useState<string>(null);
  useEffect(() => {
    if (prevApiVersion) {
      if (apiVersion && prevApiVersion !== apiVersion && router.isReady) {
        // Take some actions if the API version has changed
        console.log(`API version changed from ${prevApiVersion} to ${apiVersion}. Reloading application.`);
        setPrevApiVersion(apiVersion);

        // todo consider other actions which may need to be taken, such as clearing user notifications

        // Reload the app
        router.reload();
      }
    } else {
      // If there's no previous API version, set it and don't do more
      setPrevApiVersion(apiVersion);
    }
  }, [apiVersion, prevApiVersion, router]);

  // Disabled unused symbols warning, was inappropriately flagging the fetcher in SWR
  // noinspection JSUnusedGlobalSymbols
  return (
    <StrictMode>
      <SessionProvider session={session} refetchOnWindowFocus={true} refetchInterval={USER_REFRESH_FREQUENCY_SECONDS}>
        <SWRConfig
          value={{
            // Basic fetcher for any core resources
            fetcher: defaultSwrFetcher,

            // Set error retry values
            shouldRetryOnError: true,
            onErrorRetry: defaultSwrOnErrorRetry,

            // Dedupe (discard) any request with the same key within 2s
            dedupingInterval: SWR_DEDUPING_INTERVAL_MS,

            // Generally revalidate on any meaningful event
            revalidateOnReconnect: true,
            revalidateOnMount: true,
            revalidateIfStale: true,
            revalidateOnFocus: true,

            // Trigger the onLoadingSlow event after a timeout
            loadingTimeout: SWR_LOADING_TIMEOUT_MS,
            onLoadingSlow: (key, config) =>
              console.warn('Key', key, 'took more than', SWR_LOADING_TIMEOUT_MS / 1000, 'seconds with config', config),
          }}
        >
          <StyletronProvider value={styletron}>
            <BaseProvider
              theme={MainTheme}
              zIndex={0 /* Must set a value here for layering to work properly */} /* todo: make dark theme available */
            >
              <ReduxProvider store={store}>
                <Head>
                  <link rel="canonical" href={canonicalPath} />

                  {/* Prevent all search engines from indexing the site if not running production */}
                  {process.env.NEXT_PUBLIC_RUNTIME_ENV === 'production' ? null : (
                    <meta name="robots" content="noindex" />
                  )}

                  {/* Meta properties which are site-wide and generally not overridden */}
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
                  />
                  <meta property="og:url" content={canonicalPath} key="og-url" />
                  <meta property="og:type" content="website" key="og-type" />
                  <meta property="og:site_name" content="Benefeature" key="og-site-name" />

                  {/* Properties which are likely to be overridden on a per-page basis by a CommonMetaHead */}
                  <meta property="og:title" content="Benefeature" key="og-title" />
                  <meta
                    property="og:description"
                    /* Default is a generic BFR slogan */
                    content="Better benefits, better data, better insights"
                    key="og-description"
                  />
                  <meta
                    property="og:image"
                    /* Default is a 1200x630 logo (og:image standard size) */
                    content={ikImg(DEFAULT_META_IMAGE)}
                    key="og-image"
                  />
                </Head>

                <PageLayout {...pageProps}>
                  <Component
                    {...pageProps}
                    key={
                      /* Leverage the canonical path to prevent query param changes from forcing a full refresh, e.g. on the search pages */
                      canonicalPath
                    }
                  />
                </PageLayout>

                {/* Inject the loading overlay component */}
                <BeLoadingPageOverlay />
              </ReduxProvider>
            </BaseProvider>
          </StyletronProvider>
        </SWRConfig>
      </SessionProvider>
    </StrictMode>
  );
}
