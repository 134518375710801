import { Block } from 'baseui/block';
import { LabelLarge, ParagraphLarge, ParagraphMedium } from 'baseui/typography';
import { BaseWebHelpers, BeCard, FormatHelpers } from '@benefeature/shared/ui';
import { useState } from 'react';
import { Input } from 'baseui/input';
import toNumber from 'lodash-es/toNumber';
import { useStyletron } from 'baseui';
import { ContactEnum } from '@benefeature/shared-common';
import * as React from 'react';

export const ENTERPRISE_USER_COUNT_THRESHOLD = 50;

export type PRO_PRICE_TIERS_TYPE = {
  usersLowerBound: number;
  usersUpperBound: number;
  perUserPriceMonthly: number;
  perUserPriceAnnually: number;
};

export const PRO_PRICE_TIERS: PRO_PRICE_TIERS_TYPE[] = [
  {
    usersLowerBound: 1,
    usersUpperBound: 2,
    perUserPriceMonthly: 160,
    perUserPriceAnnually: 1925,
  },
  {
    usersLowerBound: 3,
    usersUpperBound: 12,
    perUserPriceMonthly: 138,
    perUserPriceAnnually: 1650,
  },
  {
    usersLowerBound: 13,
    usersUpperBound: 24,
    perUserPriceMonthly: 128,
    perUserPriceAnnually: 1540,
  },
  {
    usersLowerBound: 25,
    usersUpperBound: null,
    perUserPriceMonthly: 124,
    perUserPriceAnnually: 1485,
  },
];

const ProPriceTier = ({
  tier,
  onClick,
  isHighlighted,
}: {
  tier: PRO_PRICE_TIERS_TYPE;
  onClick: () => void;
  isHighlighted?: boolean;
}) => {
  return (
    <BeCard
      hoverHeight={'z6'}
      onClick={(e) => {
        // Prevent propagation and set the selected tier to this one
        e.preventDefault();
        onClick();
      }}
      isHighlighted={isHighlighted}
    >
      <Block width={'140px'} display={'flex'} flexDirection={'column'} gridRowGap={'8px'} alignItems={'center'}>
        <LabelLarge marginTop={0} marginBottom={0} overrides={{ Block: { style: { fontWeight: '500' } } }}>
          {tier.usersLowerBound}
          {tier.usersUpperBound ? ` to ${tier.usersUpperBound} ` : ' or more '}
        </LabelLarge>

        <LabelLarge marginTop={0}>
          {FormatHelpers.numberToCurrency(tier.perUserPriceAnnually, {
            maximumFractionDigits: 0,
          })}
          <span style={{ fontSize: '0.7rem', fontWeight: 'normal' }}>/user</span>
        </LabelLarge>
      </Block>
    </BeCard>
  );
};

const minUsers = 1;
const maxUsers = 99;

export default function ProPricingHelper() {
  const [, /*css*/ theme] = useStyletron();

  const [selectedTierIdx, setSelectedTierIdx] = useState(0);
  const [userCount, setUserCount] = useState(1);

  return (
    <Block display={'flex'} flexDirection={'column'} gridRowGap={'12px'} alignItems={'center'}>
      {/* User cost calculator */}
      <Block display={'flex'} flexDirection={'column'} gridRowGap={'16px'} alignItems={'center'}>
        <LabelLarge color={theme.colors.contentSecondary} marginBottom={0} marginTop={0}>
          How many users are expected?
        </LabelLarge>

        <Block width={'100px'}>
          <Input
            size={'large'}
            clearable={true}
            value={userCount}
            overrides={{
              Root: { style: { backgroundColor: 'transparent' } },
              Input: { style: { textAlign: 'center', borderBottom: '2px solid black', fontSize: '22px' } },
              InputContainer: { style: { backgroundColor: 'transparent' } },
            }}
            onBlur={() => {
              if (userCount == null) {
                // Set the user count to the default minimum
                setUserCount(minUsers);
              } else {
                // Do nothing
              }
            }}
            onClear={() => setUserCount(null)}
            onChange={(e) => {
              let newUserCount = null;
              if (e.target.value !== undefined) {
                const targetNumber = toNumber(e.target.value);
                // noinspection NegatedIfStatementJS,IfStatementWithTooManyBranchesJS
                if (!targetNumber) {
                  /* NaN/nullish */
                  newUserCount = null;
                } else if (targetNumber > maxUsers) {
                  newUserCount = maxUsers;
                } else if (targetNumber < minUsers) {
                  newUserCount = minUsers;
                } else {
                  newUserCount = targetNumber;
                }
              }
              setUserCount(newUserCount);

              // Set the selected tier to a value matching the new user count if that doesn't fall into the currently selected range
              if (
                !newUserCount ||
                newUserCount < PRO_PRICE_TIERS[selectedTierIdx].usersLowerBound ||
                newUserCount > (PRO_PRICE_TIERS[selectedTierIdx].usersUpperBound ?? newUserCount)
              ) {
                const newIdx = PRO_PRICE_TIERS.findIndex(
                  (t) => t.usersLowerBound <= newUserCount && (t.usersUpperBound ?? newUserCount) >= newUserCount
                );
                setSelectedTierIdx(newIdx === -1 ? 0 : newIdx);
              }
            }}
          />
        </Block>

        {/* Show the calculations */}
        <ProPricingHelperUserCostCalculator userCount={userCount} />

        <Block
          display={'flex'}
          gridColumnGap={'12px'}
          gridRowGap={'12px'}
          flexWrap
          alignItems={'center'}
          justifyContent={'center'}
        >
          <BeCard
            overrides={{
              Root: {
                style: {
                  width: '170px',
                  ...BaseWebHelpers.expandBorderStyles({
                    borderWidth: 0,
                    borderStyle: 'solid',
                    borderColor: 'transparent',
                  }),
                },
              },
            }}
          >
            <Block display={'flex'} flexDirection={'column'} gridRowGap={'8px'} alignItems={'center'}>
              <ParagraphLarge marginBottom={0} overrides={{ Block: { style: { fontWeight: '500' } } }}>
                User tier
              </ParagraphLarge>

              <ParagraphLarge marginTop={0}>Annual cost</ParagraphLarge>
            </Block>
          </BeCard>

          {/* Map the actual prices */}
          {PRO_PRICE_TIERS.map((tier, idx) => {
            return (
              <ProPriceTier
                key={`pro-pricing-tier-${tier.usersLowerBound}-to-${tier.usersUpperBound || 'unbounded'}`}
                tier={tier}
                onClick={() => {
                  setSelectedTierIdx(idx);

                  // Default the user count selection to the lower bound of a tier range if it doesn't fall into the newly selected range
                  if (
                    !userCount ||
                    userCount < PRO_PRICE_TIERS[idx].usersLowerBound ||
                    userCount > (PRO_PRICE_TIERS[idx].usersUpperBound ?? userCount)
                  ) {
                    setUserCount(PRO_PRICE_TIERS[idx].usersLowerBound);
                  }
                }}
                isHighlighted={idx === selectedTierIdx}
              />
            );
          })}
        </Block>
      </Block>
    </Block>
  );
}

export type ProPricingHelperUserCostCalculatorProps = {
  userCount: number;
  hideMonthly?: boolean;
};

export const ProPricingHelperUserCostCalculator = ({
  userCount,
  hideMonthly,
}: ProPricingHelperUserCostCalculatorProps) => {
  const currentTier =
    PRO_PRICE_TIERS.find((p) => p.usersLowerBound <= userCount && (p.usersUpperBound || userCount) >= userCount) ||
    PRO_PRICE_TIERS[0];

  return (
    <>
      {/* Show the math */}
      <Block display={'flex'} flexDirection={'column'} gridRowGap={'12px'} alignItems={'flex-start'}>
        {/* Annual billing */}
        <Block display={'flex'} gridColumnGap={'24px'} alignItems={'flex-start'}>
          <ParagraphMedium marginTop={0} marginBottom={0}>
            {userCount || 0} user{userCount === 1 ? '' : 's'} x{' '}
            {FormatHelpers.numberToCurrency(currentTier.perUserPriceAnnually, {
              maximumFractionDigits: 0,
            })}
            {' = '}
            {FormatHelpers.numberToCurrency(userCount * currentTier.perUserPriceAnnually, {
              maximumFractionDigits: 0,
            })}{' '}
            total per year
          </ParagraphMedium>
        </Block>
      </Block>

      {/* Special note about larger counts of users making the subscriber eligible for Enterprise licensing */}
      {userCount >= ENTERPRISE_USER_COUNT_THRESHOLD ? (
        <BeCard isHighlighted>
          <ParagraphLarge>
            With {userCount} users your team is eligible for Enterprise access.
            <br />
            <br />
            Please contact our support team for information about our Enterprise program and get tailored pricing:
            <br />
            <a /* Special onclick handler to open the mailto in a new tab/window if possible */
              onClick={(event) => {
                window.open(`mailto:${ContactEnum.HELLO_EMAIL}`, 'mail');
                event.preventDefault();
              }}
              href={`mailto:${ContactEnum.HELLO_EMAIL}`}
            >
              {ContactEnum.HELLO_EMAIL}
            </a>
          </ParagraphLarge>
        </BeCard>
      ) : null}
    </>
  );
};
