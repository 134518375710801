import { StatefulTooltip, StatefulTooltipProps } from 'baseui/tooltip';
import { mergeOverrides, useStyletron } from 'baseui';
import { PopoverOverrides } from 'baseui/popover';
import { ParagraphSmall } from 'baseui/typography';
import { TriggerType } from 'baseui/popover/types';
import { TetherPlacement } from 'baseui/layer';
import { ReactNode, useState } from 'react';
import { BaseWebHelpers } from '../helpers';

export enum BeTooltipType {
  GRAY = 'gray',
  BLUE = 'blue',
  WHITE = 'white',
  NEGATIVE = 'negative',
}

export type BeTooltipProps = Omit<
  StatefulTooltipProps,
  'triggerType' | 'dismissOnClickOutside' | 'dismissOnEsc' | 'placement'
> & {
  type?: BeTooltipType;
  children: ReactNode;
  disabled?: boolean;
  /* Must omit the following from StatefulTooltipProps and redeclare as optional (BaseWeb v12 TS update) */
  triggerType?: TriggerType;
  dismissOnClickOutside?: boolean;
  dismissOnEsc?: boolean;
  placement?: TetherPlacement;
  /* Set controlled to true to allow stateful open/close controls */
  isControlled?: boolean;
  isOpen?: boolean;
};

export const DefaultTooltipContent = ({ children }) => {
  const [, /*css*/ $theme] = useStyletron();
  return (
    <ParagraphSmall
      marginTop={0}
      marginBottom={0}
      overrides={{ Block: { style: { whiteSpace: 'pre-line' } } }}
      color={$theme.colors.contentInversePrimary}
      maxWidth={'300px'}
    >
      {children}
    </ParagraphSmall>
  );
};

export const BeTooltip = ({
  children,
  overrides,
  type,
  showArrow = true,
  popoverMargin = 16,
  disabled,
  content,
  isControlled,
  isOpen,
  ...props
}: BeTooltipProps) => {
  // Stateful handling even if it's not controlled
  const [allowOpen, setAllowOpen] = useState(false);

  if (isOpen && !isControlled) {
    // Warn if it looks like this is trying to be controlled without the right config
    console.warn(
      'Attempt to control ',
      BeTooltip.name,
      'state was made without specifying isControlled property, controls will be automatic'
    );
  }

  let currentStyles;
  switch (type) {
    case BeTooltipType.BLUE:
      currentStyles = bluePopoverOverrides;
      break;
    case BeTooltipType.WHITE:
      currentStyles = whitePopoverOverrides;
      break;
    case BeTooltipType.NEGATIVE:
      currentStyles = negativePopoverOverrides;
      break;
    default:
      currentStyles = grayPopoverOverrides;
      break;
  }

  if (!children) {
    // No children, can't display without an anchor - return null
    return null;
  }

  if (disabled) {
    return <>{children}</>;
  }

  const formattedContent =
    typeof content === 'string' ? <DefaultTooltipContent>{content}</DefaultTooltipContent> : content;

  return (
    <StatefulTooltip
      autoFocus={false}
      returnFocus={true}
      dismissOnClickOutside
      dismissOnEsc
      triggerType={'hover'}
      accessibilityType={'tooltip'}
      showArrow={showArrow}
      popoverMargin={popoverMargin}
      overrides={mergeOverrides(mergeOverrides(currentStyles as any, overrides as any), {
        Body: {
          style: {
            // Offset manually with this margin, popover margins don't appear to work properly
            ...BaseWebHelpers.margin(`${popoverMargin || 16}px`),
          },
        },
      })}
      /* Allow tooltips to exit boundaries by default */
      ignoreBoundary
      /* Default to auto placement but allow props to override */
      placement={'auto'}
      /* Default to an immediate on mouse enter trigger but allow props to override
       * NOTE: since this trigger isn't debounced properly, having the mouse leave before this fires
       * can cause the tooltip to appear and remain on the screen after focus is already lost */
      onMouseEnterDelay={0}
      /* Default to an immediate on mouse leave trigger but allow props to override
       * NOTE: changing this to anything above zero can have odd UX due to partially-faded regions preventing interactability */
      onMouseLeaveDelay={0}
      /* Default to an immediate fade of the tooltip but allow props to override
       * NOTE: changing this to anything above zero can have odd UX due to partially-faded regions preventing interactability */
      animateOutTime={0}
      onMouseEnter={() => setAllowOpen(true)}
      onFocus={() => setAllowOpen(true)}
      onMouseLeave={() => setAllowOpen(false)}
      onBlur={() => setAllowOpen(false)}
      onClick={() => {
        if (props.triggerType !== 'click') {
          /* If the object is clicked and it's a hover tooltip,
             the tooltip generally isn't helpful anymore and undefined behavior can occur if it's still allowed */
          setAllowOpen(false);
        }
      }}
      /* Handle content if it's provided as a string */
      content={
        /* Only open the tooltip if explicitly defined as allowed */
        allowOpen
          ? /* If this is a controlled tooltip, null out content if it's not open */
            isControlled
            ? isOpen
              ? formattedContent
              : null
            : formattedContent
          : null
      }
      {...props}
    >
      {children}
    </StatefulTooltip>
  );
};

export const grayPopoverOverrides: PopoverOverrides = {
  Inner: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors['gray700'],
      color: $theme.colors.white,
    }),
  },
  Arrow: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors['gray700'],
    }),
  },
};

export const bluePopoverOverrides: PopoverOverrides = {
  Inner: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors['blueAccent'],
      color: $theme.colors.white,
    }),
  },
  Arrow: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors['blueAccent'],
    }),
  },
};

export const whitePopoverOverrides: PopoverOverrides = {
  Inner: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors.white,
      color: $theme.colors.black,
    }),
  },
  Arrow: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors.white,
    }),
  },
};

export const negativePopoverOverrides: PopoverOverrides = {
  Inner: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors.backgroundNegative,
      color: $theme.colors.contentInversePrimary,
    }),
  },
  Arrow: {
    style: ({ $theme }: any) => ({
      backgroundColor: $theme.colors.backgroundNegative,
    }),
  },
};
