import { createStore } from 'redux';
import rootReducers from './root.reducers';

const __localStorageKey = 'bfrStoreState';
let __persistedState;

// Need to prevent localStorage code from being runnable on a server during SSR-type tasks
const __isServer = typeof window === 'undefined';
if (!__isServer) {
  try {
    // Check local storage for any existing store state
    __persistedState = localStorage.getItem(__localStorageKey)
      ? JSON.parse(localStorage.getItem(__localStorageKey))
      : {};
  } catch (err) {
    if (err instanceof SyntaxError) {
      __persistedState = {};
    } else {
      // Re-throw the error if it's not a SyntaxError
      throw err;
    }
  }
} else {
  __persistedState = {};
}

// Establish the store
export const store = createStore(rootReducers, __persistedState);

if (!__isServer) {
  // Write any changes to the store to the __localStorageKey
  store.subscribe(() => {
    localStorage.setItem(__localStorageKey, JSON.stringify(store.getState()));
  });
}

export default store;
