import { ActionType } from '../../models';
import { NotificationsActionTypes } from './notifications.actions';
import { UserNotifications } from '@benefeature/shared-types';

export interface NotificationsReducerState {
  hasViewed: boolean;
  currentNotifications: UserNotifications;
  anyNotificationsOpen: boolean;
}

const initialState: NotificationsReducerState = {
  hasViewed: false,
  currentNotifications: null,
  anyNotificationsOpen: false,
};

const notificationsReducer = (state = initialState, action: ActionType): NotificationsReducerState => {
  switch (action.type) {
    case NotificationsActionTypes.SET_NOTIFICATIONS_VIEWED:
      return {
        ...state,
        hasViewed: action.payload,
      };

    case NotificationsActionTypes.SET_CURRENT_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: action.payload,
      };

    case NotificationsActionTypes.SET_ANY_NOTIFICATIONS_OPEN:
      return {
        ...state,
        anyNotificationsOpen: action.payload,
      };

    default:
      return state;
  }
};

export default notificationsReducer;
