export const typography = {
  DisplayLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '96px',
    fontWeight: 500,
    lineHeight: '112px',
  },
  DisplayMedium: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '52px',
    fontWeight: 500,
    lineHeight: '64px',
  },
  DisplaySmall: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '44px',
    fontWeight: 500,
    lineHeight: '52px',
  },
  DisplayXSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '36px',
    fontWeight: 500,
    lineHeight: '44px',
  },
  HeadingXXLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '40px',
    lineHeight: '52px',
  },
  HeadingXLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '36px',
    lineHeight: '44px',
  },
  HeadingLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '40px',
  },
  HeadingMedium: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '36px',
  },
  HeadingSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
  },
  HeadingXSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '28px',
  },
  LabelLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
  },
  LabelMedium: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
  },
  LabelSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
  },
  LabelXSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  ParagraphLarge: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px',
  },
  ParagraphMedium: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  ParagraphSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  ParagraphXSmall: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
};
