import { RouteEnum, RoutesWithDefaultRedirects } from '@benefeature/shared-common';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { NextRouter } from 'next/router';
import { Session } from 'next-auth';

export const calculateNavItems = (session: Session, lastSearchURL: string, breakpoint: string, router: NextRouter) => {
  // Session has a user with a claims object
  // Can't import the type augmentation since that must exist in the app and this is in the libs
  // Using string member access to avoid error warning
  const menuState = {
    items: [
      /* Only show the dashboard links if the user has any available */
      ...(session?.user?.['claims']?.['dashboards'] && session?.user?.['claims']?.['dashboards']?.length > 0
        ? [
            {
              label: 'Dashboard',
              icon: (props) => <DashboardOutlinedIcon {...props} />,
              iconClass: DashboardOutlinedIcon,
              url: RouteEnum.DASHBOARDS,
            },
          ]
        : []),
      /* Block of items to include only if there's a logged-in user */
      ...(session?.user
        ? [
            {
              label: 'Search',
              icon: (props) => <SearchRoundedIcon {...props} />,
              iconClass: SearchRoundedIcon,
              /* Route to the last search if it's available */
              url:
                lastSearchURL &&
                /* Ensure the last search URL isn't the completely empty base variant */
                lastSearchURL !== RouteEnum.SEARCH_SEARCH_TYPE.valueOf() &&
                /* Ensure that the query params of the last search URL aren't just going to loop (BFR-1275) */
                !lastSearchURL.endsWith('?useLastSearch=true')
                  ? lastSearchURL
                  : RouteEnum.SEARCH,
            },
            {
              label: 'Favorites',
              icon: (props) => <StarBorderRoundedIcon {...props} />,
              iconClass: StarBorderRoundedIcon,
              url: RouteEnum.FAVORITES,
            },
            {
              label: 'Exports',
              icon: (props) => <DescriptionOutlinedIcon {...props} />,
              iconClass: DescriptionOutlinedIcon,
              url: RouteEnum.REPORT_EXPORTS,
            },
          ]
        : []),
      {
        label: 'Support',
        icon: (props) => <SupportAgentRoundedIcon {...props} />,
        iconClass: SupportAgentRoundedIcon,
        url: RouteEnum.SUPPORT,
      },
    ],
  };

  /* Add further menu items if this is a smaller view which would be doing a side nav instead of header */
  if (breakpoint !== 'xlarge' && !session?.user) {
    // Establish whether to set a login redirect back to the current page or leave that empty
    const redirectBackOnLogin = !RoutesWithDefaultRedirects.some((route) => {
      return router.pathname === route;
    });

    // Add login and sign up links to options if on a small view
    menuState.items.unshift({
      label: 'Sign up',
      icon: (props) => <PersonAddOutlinedIcon {...props} />,
      iconClass: PersonAddOutlinedIcon,
      url: RouteEnum.AUTH_SIGNUP,
    });
    menuState.items.unshift({
      label: 'Log in',
      icon: (props) => <LoginRoundedIcon {...props} />,
      iconClass: LoginRoundedIcon,
      url: RouteEnum.AUTH_LOGIN + (redirectBackOnLogin ? `?redirect=${encodeURIComponent(router.asPath)}` : ''),
    });
  }

  // Return the calculated menu state
  return menuState;
};
