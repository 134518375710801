import { useEffect, useState } from 'react';
import { useScript } from './useScript';
import { useRouter } from 'next/router';

export function useHubspot(disabled = false) {
  const router = useRouter();

  // Hook Hubspot scripts but conditionally exclude the URL if this should actually be disabled
  useScript('hs-script-loader', disabled ? null : '//js-na1.hs-scripts.com/6305109.js');
  useScript('hs-forms-loader', disabled ? null : '//js.hsforms.net/forms/embed/v2.js');

  // Detect whether Hubspot has loaded
  const [hubspotChatAvailable, setHubspotChatAvailable] = useState(false);
  useEffect(() => {
    if (!disabled) {
      // Set Hubspot conversations settings to try and get the widget to load immediately
      (window as any).hsConversationsSettings = {
        loadImmediately: true,
        enableWidgetCookieBanner: false,
        disableAttachment: false,
      };

      const anyWindow = window as any;
      let hsConversationsStatus;
      const hsConvAttemptMaxCnt = 20;
      const tryToLoad = (attempt = 1) => {
        anyWindow?.HubSpotConversations?.widget?.load();
        hsConversationsStatus = anyWindow?.HubSpotConversations?.widget?.status();
        if (attempt % 5 === 0) {
          console.debug(`hsConversationsStatus on attempt ${attempt}:`, hsConversationsStatus);
        }
        if (
          (!hsConversationsStatus || (hsConversationsStatus?.pending && !hsConversationsStatus.loaded)) &&
          attempt <= hsConvAttemptMaxCnt
        ) {
          setTimeout(() => tryToLoad(attempt + 1), 1000);
        }
      };
      tryToLoad();

      setHubspotChatAvailable(!!hsConversationsStatus?.loaded);
    }
  }, [router.asPath, disabled]);

  return hubspotChatAvailable;
}
