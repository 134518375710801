/**
 * Generates types of hashes
 * Avoid using crypto here - these are client-side functions and including crypto will dramatically expand bundle size
 */
import * as randomBytes from 'randombytes';

export function newHash(size?: number, seed?: string) {
  // Size defaults to 32
  // Output may be shorter than size depending on presence of spaces, slashes, and other special characters which are replaced or removed
  if (size == null) {
    size = 32;
  }
  const b = seed || randomBytes(size);
  if (b) {
    const s = b.toString('base64');
    return s.replace(/\+/g, '-').replace(/\//g, '-').replace(/=/g, '');
  } else {
    return;
  }
}
