// Converters for a base typography to a size-adjusted variant
// e.g. if fed a DisplayLarge typography but the breakpoint is medium, this will return DisplayMedium
import {
  DisplayLarge,
  DisplayMedium,
  DisplayXSmall,
  HeadingLarge,
  HeadingMedium,
  HeadingSmall,
  HeadingXSmall,
} from 'baseui/typography';
import { BlockProps } from 'baseui/block';
import * as React from 'react';
import { ComponentProps } from 'react';
import { useBreakpoint } from '@benefeature/shared/ui';

export const ResponsiveDisplayLarge = (props: BlockProps & ComponentProps<any> & { id: string }) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? DisplayLarge : DisplayMedium;
  return <UseTypo {...props} />;
};

export const ResponsiveDisplayMedium = (props: BlockProps & ComponentProps<any>) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? DisplayMedium : DisplayXSmall;
  return <UseTypo {...props} />;
};

export const ResponsiveDisplayXSmall = (props: BlockProps & ComponentProps<any>) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? DisplayXSmall : HeadingMedium;
  return <UseTypo {...props} />;
};

export const ResponsiveHeadingLarge = (props: BlockProps & ComponentProps<any>) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? HeadingLarge : HeadingMedium;
  return <UseTypo {...props} />;
};

export const ResponsiveHeadingMedium = (props: BlockProps & ComponentProps<any>) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? HeadingMedium : HeadingSmall;
  return <UseTypo {...props} />;
};

export const ResponsiveHeadingSmall = (props: BlockProps & ComponentProps<any>) => {
  const breakpoint = useBreakpoint();
  const UseTypo = ['large', 'xlarge'].includes(breakpoint) ? HeadingSmall : HeadingXSmall;
  return <UseTypo {...props} />;
};

export const ResponsiveHeadingXSmall = (props: BlockProps & ComponentProps<any>) => {
  // Note: no pivot with this one, next step down changes too much with styles (LabelLarge is approximate step)
  const UseTypo = HeadingXSmall;
  return <UseTypo {...props} />;
};
