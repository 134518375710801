import { combineReducers } from 'redux';
import searchReducer, { SearchReducerState } from './search/search.reducers';
import uiConfigReducer, { UIConfigReducerState } from './uiConfig/uiConfig.reducers';
import notificationsReducer, { NotificationsReducerState } from './notifications/notifications.reducers';

export interface AppState {
  searchReducer: SearchReducerState;
  uiConfigReducer: UIConfigReducerState;
  notificationsReducer: NotificationsReducerState;
}

const rootReducers = combineReducers({
  searchReducer,
  uiConfigReducer,
  notificationsReducer,
});

export const mapAllRootReducers = (state) => ({
  search: { ...state.searchReducer },
  uiConfig: { ...state.uiConfigReducer },
  notifications: { ...state.notificationsReducer },
});

export default rootReducers;
