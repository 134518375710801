import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useScript = (id: string, url?: string, defer?: boolean, async = false, body?: string) => {
  // Use a router to try and inject every time the path changes
  const router = useRouter();

  useEffect(() => {
    if (url || body) {
      // Check if the script already exists on the page
      const scripts = document.getElementsByTagName('script');
      const exists = Array.from(scripts).some((script) => script.id === id);

      // Inject if it doesn't exist yet
      if (!exists) {
        const script = document.createElement('script');
        script.async = async;

        if (url) {
          script.src = url;
        }

        if (id) {
          script.id = id;
        }

        if (defer) {
          script.defer = true;
        }

        if (body) {
          script.innerHTML = body;
        }

        // noinspection XHTMLIncompatabilitiesJS intended potential incompatibility
        document.body.appendChild(script);
      }
    }
  }, [url, defer, id, router.asPath, async, body]);
};
