import { Subscription } from '@benefeature/shared-types';
import { SUBSCRIPTIONS } from '@benefeature/shared-common';

/**
 * Gather the highest tier subscription for the team provided.
 * Enterprise > Pro > Basic
 *
 * @param validSubscriptions
 */
export function highestActiveSubscriptionTier(validSubscriptions: Subscription[]) {
  if (validSubscriptions && validSubscriptions.length > 0) {
    return getCurrentActiveSubscription(validSubscriptions)?.price?.product?.name || 'Basic';
  } else {
    // If team ID wasn't provided, return Basic
    return 'Basic';
  }
}

const ACTIVE_SUBSCRIPTION_STATUSES = ['active', 'trialing'];

/**
 * This should always be able to account for things like cancellations or trial expirations.
 *
 * However, a vulnerability would exist if Stripe webhooks fail since the status may remain
 * in its active/trialing state beyond the expected trial end date or current period end date,
 * potentially extending the active period past authorization.
 *
 * Due to this, also check that the current datetime is prior to either the trial end date if trialing
 * or the current period end date if active. **/
export function subscriptionActiveAndMatches(subscription: Subscription, checkSubscriptionLevel: SUBSCRIPTIONS) {
  return (
    ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription?.status) &&
    new Date() <
      new Date(subscription?.status === 'active' ? subscription?.current_period_end : subscription?.trial_end) &&
    subscription?.price?.product?.name === checkSubscriptionLevel
  );
}

/**
 * Take an array of subscriptions and return an active one with the latest current_period_end,
 * with highest quantity as a tiebreaker.
 *
 * Validates the current date against the current_period_end or trial_end values.
 *
 * @param subscriptions
 */
export function getCurrentActiveSubscription(subscriptions: Subscription[]): Subscription {
  if (!subscriptions || subscriptions.length === 0) {
    // No subscriptions available at all, return null
    return null;
  }

  // Identify active subscriptions
  const activeSubscriptions = subscriptions.filter(
    (s) =>
      ACTIVE_SUBSCRIPTION_STATUSES.includes(s?.status) &&
      new Date() < new Date(s?.status === 'active' ? s?.current_period_end : s?.trial_end)
  );

  if (activeSubscriptions?.length === 0) {
    // No subscriptions are active, return null
    return null;
  }

  if (activeSubscriptions.length === 1) {
    // If filtering to active options has already reduced to one value, use that one
    return activeSubscriptions[0];
  } else {
    // More to calculate to establish which subscription should be returned
    // Identify the latest period end
    const latestPeriodEndTime = Math.max(
      ...activeSubscriptions
        .filter((s) => s.current_period_end?.length > 0)
        .map((s) => new Date(s.current_period_end).getTime())
    );

    // Check the count of the latest period ends and if there's only one, set to that subscription
    const latestPeriodCnt = activeSubscriptions.filter(
      (s) => new Date(s.current_period_end).getTime() === latestPeriodEndTime
    )?.length;
    if (latestPeriodCnt === 1) {
      return activeSubscriptions.find((s) => new Date(s.current_period_end).getTime() === latestPeriodEndTime);
    } else {
      // There were multiple with the same latest period
      // Use the one with the greatest quantity of the remaining pool
      // No further tiebreakers - this will take the first one having the max quantity
      return activeSubscriptions
        .filter((s) => new Date(s.current_period_end).getTime() === latestPeriodEndTime)
        .reduce(function (prev, current) {
          return prev && prev.quantity > current.quantity ? prev : current;
        });
    }
  }
}
