import { PERMISSIONS, SUBSCRIPTIONS } from '@benefeature/shared-common';
import { UserRole } from '@benefeature/shared-types';

export const UNSUBSCRIBED_SEARCH_LIMIT_MAX = 10;

export const DEFAULT_PAGE = 1;
export const DEFAULT_SEARCH_RESULT_LIMIT = 10;
export const DEFAULT_EXPORT_MAX_RESULTS_PER_BATCH = 1000;

export const TOP_EMPLOYERS_DEFAULT_LIMIT = 5;

export const MARKET_PRESENCE_DEFAULT_LIMIT = 5;
export const MARKET_PRESENCE_DETAILED_DEFAULT_LIMIT = 10;
export const MARKET_PRESENCE_MAX_LIMIT = 40;

// Defines profile filters which should have the effect of disabling market presence and competition info
export const DISABLE_MARKET_PRESENCE_FILTERS = ['prior-carrier-name', 'wins-from-name'];

/**
 * Defines how many results a Pro access subscription should be restricted to during exports (BFR-1061)
 */
export const PRO_TOTAL_RESULT_LIMIT = 200;

export const TREND_HISTORY_YEARS_DEFAULT_LOOKBACK = 5;

export const EXCEL_MAX_WORKSHEET_NAME_LENGTH = 30;

export const DEFAULT_ALL_OTHERS_THRESHOLD_RATIO = 0.01;

export const PRIMARY_PRODUCTS_CASE_RATIO_THRESHOLD = 0.01;
export const PRIMARY_PRODUCTS_PREMIUM_RATIO_THRESHOLD = 0.005;

// 7 days
export const NEXTAUTH_SESSION_MAX_AGE_SECS = 7 * 24 * 60 * 60;
// 1 hour
export const NEXTAUTH_SESSION_UPDATE_AGE_SECS = 1 * 60 * 60;
// 3 minutes
export const NEXTAUTH_VERIFICATION_TOKEN_WINDOW_AGE_MS = 180000;
// 3 uses max
export const NEXTAUTH_VERIFICATION_TOKEN_MAX_USES = 3;

// Used to multiply out Stripe's seconds-based epoch dates to millisecond-based dates
export const STRIPE_DATE_CONVERSION_FACTOR = 1000;

export const AUTH_VERIFY_REQUEST_QUERY_PARAM = 'verification-link';

// Used to define the offset in pixels before the top of a given anchor element which triggers a change in the active anchor state
export const DEFAULT_SCROLL_BUFFER_PX = 10;

export const SUBSCRIBED_ACCESS_ROLES: UserRole[] = [
  { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
  { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
  { hasSubscriptions: [SUBSCRIPTIONS.PRO] },
];

export const ENTERPRISE_ACCESS_ROLES: UserRole[] = [
  { hasPermissions: [PERMISSIONS.SYSTEM_ADMIN] },
  { hasSubscriptions: [SUBSCRIPTIONS.ENTERPRISE] },
];

export const clamp2LinesBlockOverride = {
  Block: {
    style: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      userSelect: 'none',
      whiteSpace: 'break-spaces',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
};

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const ALL_PRODUCTS = [
  'LTD',
  'STD',
  'Life',
  'Health',
  'Dental',
  'Vision',
  'Prescription Drug',
  'Stop Loss',
  'AD&D',
  'Accident',
  'Hospital',
  'EAP',
  'Cancer',
  'Critical Illness',
  'Business Travel',
  'Paid Family Leave',
  'Long Term Care',
  'Prepaid Legal',
  'Organ Transplant',
  'Dependent Life',
  'Major Medical',
  'Medical Abroad',
  'Medical Bridge',
];
