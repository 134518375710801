import { ReactNode, useEffect, useState } from 'react';
import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import { BeCard } from './Cards';
import { BeButton } from '../forms';
import { Cell, Grid } from 'baseui/layout-grid';
import { CallToAction } from '@benefeature/shared-types';

enum LOCK_SIZES {
  'small',
  'default',
  'large',
}

const TRANSITION_TIMING_MS = 100;

export type BeLockedContentOverlayProps = {
  isLocked: boolean;
  children: ReactNode;
  lockSize?: keyof typeof LOCK_SIZES;
  backgroundColor?: string;
  detailsDescription?: ReactNode;
  detailsPrimaryCTA?: CallToAction;
  detailsSecondaryCTA?: CallToAction;
  compactDetails?: boolean;
  alignToStart?: boolean;
};

export const BeLockedContentOverlay = ({
  isLocked,
  children,
  lockSize = 'default',
  backgroundColor,
  detailsDescription,
  detailsPrimaryCTA,
  detailsSecondaryCTA,
  compactDetails = false,
  alignToStart = false,
}: BeLockedContentOverlayProps) => {
  const [css, $theme] = useStyletron();

  const hiddenBlock = css({
    opacity: 0,
    transform: 'scaleY(0)',
    maxHeight: 0,
  });
  const shownBlock = css({
    transition: `opacity ${TRANSITION_TIMING_MS}ms ease-in-out, transform ${TRANSITION_TIMING_MS}ms ease-in-out, max-height ${TRANSITION_TIMING_MS}ms ease-in-out`,
    opacity: 1,
    transform: 'scaleY(1)',
    maxHeight: '10000px',
  });

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [hasAnyDetails, setHasAnyDetails] = useState(
    !!detailsDescription || !!detailsPrimaryCTA || !!detailsSecondaryCTA
  );
  useEffect(() => {
    setHasAnyDetails(!!detailsDescription || !!detailsPrimaryCTA || !!detailsSecondaryCTA);
  }, [detailsDescription, detailsPrimaryCTA, detailsSecondaryCTA]);

  // Have details and lock display logic as offsets from each other, for a total cycle time of 2 x transitionTimingMs
  const [showLock, setShowLock] = useState(!isHovered);
  const [showLockTimerID, setShowLockTimerID] = useState(null);
  const [showDetails, setShowDetails] = useState(hasAnyDetails && isHovered);
  const [showDetailsTimerID, setShowDetailsTimerID] = useState(null);
  useEffect(() => {
    // Only change display state of lock and details if there are any details, otherwise don't hide the lock at all
    if (hasAnyDetails) {
      const _interimValue = isHovered;
      clearTimeout(showLockTimerID);
      setShowLockTimerID(
        setTimeout(() => {
          setShowLock(!_interimValue);
        }, TRANSITION_TIMING_MS)
      );
      clearTimeout(showDetailsTimerID);
      setShowDetailsTimerID(
        setTimeout(() => {
          setShowDetails(_interimValue);
        }, TRANSITION_TIMING_MS)
      );
    }
    // Don't include timer ID in hook dependencies, will cause infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, hasAnyDetails]);

  // Return null if no children
  if (!children) {
    return null;
  } else if (!isLocked) {
    // Allow fragment here, children can have a root of multiple elements
    return <>{children}</>;
  } else {
    return (
      <Block
        onMouseEnter={() => handleMouseEnter()}
        onMouseLeave={() => handleMouseLeave()}
        position={'relative'}
        width={'100%'}
        height={'100%'}
      >
        {/* Children elements to be blurred on lock */}
        {children}

        {/* Block to apply blur and background effects */}
        <Block
          position={'absolute'}
          top={0}
          bottom={0}
          left={0}
          right={0}
          /* Set background to either the provided color or primary, with a 40% opacity (hex 66) */
          backgroundColor={`${backgroundColor || $theme.colors.backgroundPrimary}66`}
          overrides={{ Block: { style: { backdropFilter: 'blur(4px)', '-webkit-filter': 'blur(4px)' } } }}
        />

        {/* Block to provide lock content information */}
        <Block
          position={'absolute'}
          display={'flex'}
          justifyContent={alignToStart ? 'start' : 'center'}
          flexDirection={'column'}
          alignItems={'center'}
          top={0}
          bottom={0}
          left={0}
          right={0}
        >
          {/* Lock icon block, shown when not hovered */}
          <Block className={`${hiddenBlock}${showLock ? ', ' + shownBlock : ''}`}>
            <LockOpenRoundedIcon
              style={{
                color: $theme.colors.accent,
                fontSize: lockSize === 'default' ? '56px' : lockSize === 'large' ? '70px' : '24px',
                transform: 'scaleX(-1)',
              }}
            />
          </Block>

          {/* Details block, shown when hovered if there are any details to show */}
          {hasAnyDetails && (
            <Block
              className={`${hiddenBlock}${showDetails ? ', ' + shownBlock : ''}`}
              maxWidth={
                compactDetails
                  ? '90%'
                  : /* Between 200px and 600px, edging toward the container size until the max */
                    'min(max(200px, 100%), 600px)'
              }
            >
              <BeCard
                overrides={{
                  Body: { style: { marginBottom: 0 } },
                  Contents: {
                    style: { paddingTop: compactDetails ? '8px' : '12px', paddingBottom: 0, marginBottom: 0 },
                  },
                }}
              >
                <Grid gridMargins={compactDetails ? 8 : 16}>
                  {detailsDescription && <Cell span={[2, 4, 8, 12]}>{detailsDescription}</Cell>}

                  {(!!detailsPrimaryCTA || !!detailsSecondaryCTA) && (
                    <Cell span={[2, 4, 8, 12]}>
                      {detailsPrimaryCTA && (
                        <BeButton kind={'primary'} onClick={() => detailsPrimaryCTA.action()}>
                          {detailsPrimaryCTA.actionVerb}
                        </BeButton>
                      )}

                      {detailsSecondaryCTA && (
                        <BeButton
                          overrides={{
                            BaseButton: { style: { ...(detailsPrimaryCTA ? { marginLeft: '12px' } : {}) } },
                          }}
                          kind={'tertiary'}
                          onClick={() => detailsSecondaryCTA.action()}
                        >
                          {detailsSecondaryCTA.actionVerb}
                        </BeButton>
                      )}
                    </Cell>
                  )}
                </Grid>
              </BeCard>
            </Block>
          )}
        </Block>
      </Block>
    );
  }
};
