import { createDarkTheme, createTheme } from 'baseui';
import { colors, foundation } from './colors';
import { Theme, ThemePrimitives } from 'baseui/theme';
import { typography } from './typography';
import { FormatHelpers } from '../../helpers';
import { ALL_PRODUCTS } from '../../models';
import { BUTTON_VARIANTS } from '../../forms';

const primitives: Partial<ThemePrimitives> = {
  // ...lightThemePrimitives,
  ...colors,
  ...foundation,

  primaryFontFamily: 'Poppins',
};

const elevationTransitions = {
  transition: 'box-shadow 300ms ease-in-out',
};

const elevations = {
  z0: {
    boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px rgba(0, 0, 0, 0.06)',
    ...elevationTransitions,
  },
  z1: {
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.06)',
    ...elevationTransitions,
  },
  z2: {
    boxShadow: '0px 0px 0px 1px rgba(15, 15, 15, 0.09), 0px 2px 4px rgba(15, 15, 15, 0.08)',
    ...elevationTransitions,
  },
  z4: {
    boxShadow:
      '0px 3px 6px -2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(15, 15, 15, 0.08), 0px 6px 12px rgba(15, 15, 15, 0.08)',
    ...elevationTransitions,
  },
  z6: {
    boxShadow:
      '0px 6px 8px -4px rgba(0, 0, 0, 0.08), 0px 0px 0px 1px rgba(15, 15, 15, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.15)',
    ...elevationTransitions,
  },
  z8: {
    boxShadow:
      '0px 8px 24px rgba(15, 15, 15, 0.14), 0px 3px 6px rgba(15, 15, 15, 0.08), 0px 0px 0px 1px rgba(15, 15, 15, 0.04)',
    ...elevationTransitions,
  },
  z16: {
    boxShadow:
      '0px 24px 38px 3px rgba(15, 15, 15, 0.15), 0px 9px 46px 8px rgba(15, 15, 15, 0.1), 0px 12px 16px -8px rgba(15, 15, 15, 0.1)',
    ...elevationTransitions,
  },
};

export const chartContentColors = {
  // Order of colors here dictates order presented in UI
  // See Charts/helpers.ts#getChartColor for implementation
  BlueAccent: colors.blueAccent,
  YellowAccent: colors.yellowAccent,
  DarkGreen: colors.green400,
  Orange: colors.yellow900,
  DarkBlue: colors.blue700,
  LightBlue: colors.blue100,
  // Deprecate yellow600 use since yellowAccent is so close, replace with red400 (BFR-371)
  MediumRed: colors.red400,
  // Yellow: colors.yellow600,
  Blue: colors.blue400,
  Red: '#E43333',
  Green: colors.green300,
  MediumYellow: colors.yellow300,
  AquaBlue: '#00A9EC',
  Pink: '#FF636C',
  Emerald: '#00BBAD',
  LightYellow: colors.yellow200,
  LightPurple: '#B662A8',
  Teal: colors.green100,
  LightOrange: '#FFC4A1',
  OrangePale: '#FE855C',
  DarkPurple: '#6B48A3',
  DarkSlateGray: '#2F4F4F',
  DarkRed: '#8B0000',
  Salmon: '#FA8072',
};

/*
  Alternative color palette with 21 colors:
  darkred #8b0000, olive #808000, darkslateblue #483d8b, mediumseagreen #3cb371,
  darkcyan #008b8b, darkblue #00008b, red #ff0000, darkorange #ff8c00,
  yellow #ffff00, chartreuse #7fff00, mediumorchid #ba55d3, springgreen #00ff7f,
  aqua #00ffff, blue #0000ff, fuchsia #ff00ff, dodgerblue #1e90ff, khaki #f0e68c,
  salmon #fa8072, plum #dda0dd, deeppink #ff1493, lightskyblue #87cefa

  Generated from https://mokole.com/palette.html
*/

// Define dynamic product colors for general usage and include all permutations of HMO and PPO contract flagging as well (BFR-371)
const productColorsBase = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    k,
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsHMO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, true, false),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsPPO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, false, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsHMOPPO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, true, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsASO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, false, false, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsASOHMO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, true, false, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsASOPPO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, false, true, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
const productColorsASOHMOPPO = Object.fromEntries(
  ALL_PRODUCTS.map((k, i) => [
    FormatHelpers.productNameFormatter(k, true, true, true),
    Object.values(chartContentColors)[
      (Object.keys(chartContentColors).length + i) % Object.keys(chartContentColors).length
    ],
  ])
);
export const productColors = {
  ...productColorsBase,
  ...productColorsHMO,
  ...productColorsPPO,
  ...productColorsHMOPPO,
  ...productColorsASO,
  ...productColorsASOHMO,
  ...productColorsASOPPO,
  ...productColorsASOHMOPPO,
};

export const brokerRoleSplitColors = {
  PRIMARY: chartContentColors.Orange,
  SECONDARY: chartContentColors.BlueAccent,
  OTHER: colors.gray300,
};

export const buttonVariantsColors: any = {};
buttonVariantsColors[BUTTON_VARIANTS.destructive] = {
  buttonPrimaryFill: colors.red400,
  buttonPrimaryText: colors.white,
  buttonPrimaryActive: colors.red600,
  buttonPrimaryHover: colors.red500,
  buttonPrimarySelectedFill: colors.red600,
  buttonPrimarySelectedText: colors.white,

  buttonSecondaryFill: colors.red50,
  buttonSecondaryHover: colors.red100,
  buttonSecondaryActive: colors.red200,
  buttonSecondaryText: colors.red400,

  buttonTertiaryActive: colors.red100,
  buttonTertiaryHover: colors.red50,
  buttonTertiaryFill: colors.white,
  buttonTertiaryText: colors.red400,
  buttonTertiarySelectedFill: colors.red100,
  buttonTertiarySelectedText: colors.red400,
};

buttonVariantsColors[BUTTON_VARIANTS.positive] = {
  buttonPrimaryFill: colors.green400,
  buttonPrimaryText: colors.white,
  buttonPrimaryActive: colors.green600,
  buttonPrimaryHover: colors.green500,
  buttonPrimarySelectedFill: colors.green600,
  buttonPrimarySelectedText: colors.white,

  buttonSecondaryFill: colors.green50,
  buttonSecondaryHover: colors.green100,
  buttonSecondaryActive: colors.green200,
  buttonSecondaryText: colors.green400,

  buttonTertiaryActive: colors.green100,
  buttonTertiaryHover: colors.green50,
  buttonTertiaryFill: colors.white,
  buttonTertiaryText: colors.green400,
  buttonTertiarySelectedFill: colors.green100,
  buttonTertiarySelectedText: colors.green400,
};

buttonVariantsColors[BUTTON_VARIANTS.warning] = {
  buttonPrimaryFill: colors.yellow500,
  buttonPrimaryText: colors.black,
  buttonPrimaryActive: colors.yellow700,
  buttonPrimaryHover: colors.yellow600,
  buttonPrimarySelectedFill: colors.yellow700,
  buttonPrimarySelectedText: colors.black,

  buttonSecondaryFill: colors.yellow50,
  buttonSecondaryHover: colors.yellow100,
  buttonSecondaryActive: colors.yellow200,
  buttonSecondaryText: colors.black,

  buttonTertiaryActive: colors.yellow100,
  buttonTertiaryHover: colors.yellow50,
  buttonTertiaryFill: colors.white,
  buttonTertiaryText: colors.yellow500,
  buttonTertiarySelectedFill: colors.yellow100,
  buttonTertiarySelectedText: colors.yellow500,
};

// const overrides:Partial<Theme> = {
const overrides = {
  lighting: {
    shadow400: elevations.z1.boxShadow,
    shadow500: elevations.z2.boxShadow,
    shadow600: elevations.z4.boxShadow,
    shadow700: elevations.z8.boxShadow,
  },
  colors: {
    backgroundPrimary: colors.white,
    backgroundSecondary: colors.gray50,
    backgroundTertiary: colors.gray100,
    backgroundAdmin: colors.blue200,
    backgroundInversePrimary: colors.black,
    backgroundInverseSecondary: colors.gray800,
    backgroundInverseAdmin: colors.blue900,

    contentPrimary: foundation.primaryA,
    contentSecondary: colors.gray600,
    contentTertiary: colors.gray500,
    contentInversePrimary: foundation.primaryB,
    contentInverseSecondary: colors.gray300,
    contentInverseTertiary: colors.gray400,

    borderOpaque: colors.gray200,
    borderTransparent: `${foundation.primaryA}08`,
    borderSelected: foundation.primaryA,
    borderInverseOpaque: colors.gray700,
    borderInverseTransparent: `${foundation.primaryB}20`,
    borderInverseSelected: foundation.primaryB,

    backgroundStateDisabled: colors.gray50,
    backgroundOverlayDark: `${colors.black}30`,
    backgroundOverlayLight: `${colors.black}08`,
    backgroundAccent: foundation.accent,
    backgroundNegative: foundation.negative,
    backgroundWarning: foundation.warning,
    backgroundPositive: foundation.positive,

    backgroundLightAccent: colors.blue50,
    backgroundLightNegative: colors.red50,
    backgroundLightWarning: colors.yellow50,
    backgroundLightPositive: colors.green50,
    backgroundAlwaysDark: colors.black,
    backgroundAlwaysLight: colors.white,

    contentStateDisabled: colors.gray400,
    contentOnColor: colors.white,
    contentAccent: foundation.accent,
    contentNegative: foundation.negative,
    contentWarning: foundation.warning,
    contentPositive: foundation.positive,

    borderStateDisabled: colors.gray50,
    borderAccent: foundation.accent,
    borderAccentLight: colors.blue100,
    borderNegative: colors.red200,
    borderWarning: colors.yellow300,
    borderPositive: colors.green200,
    borderFocus: foundation.accent,

    menuFillHover: '#E7EDFF80',

    // Inputs
    inputBorder: 'transparent',

    inputFillError: '#FFEFED',
    inputBorderError: '#F1998E',

    inputFillPositive: '#E8F0FE',
    inputBorderPositive: '#E8F0FE',
    inputFillActive: '#E7EDFF',

    //Checkbox
    tickFillSelected: colors.blueAccent,
    tickFillDisabled: `${colors.blueAccent}66`,
    tickFillActive: colors.blue700,
    tickFillHover: colors.blue800,
    tickMarkFill: colors.white,
    tickBorder: colors.gray500,
    tickFillSelectedHover: colors.blue800,

    //Toggle
    toggleFill: colors.blueAccent,

    //Tag
    tagPrimarySolidBackground: colors.blue300,

    //Buttons
    buttonDisabledFill: colors.gray50,
    buttonDisabledText: colors.gray400,

    buttonPrimaryFill: colors.blue500,
    buttonPrimaryHover: colors.blue700,
    buttonPrimaryActive: colors.blue900,
    buttonPrimaryText: colors.white,

    buttonSecondaryFill: colors.blue50,
    buttonSecondaryHover: colors.blue100,
    buttonSecondaryActive: colors.blue200,
    buttonSecondaryText: colors.blue900,

    buttonTertiaryFill: colors.white,
    buttonTertiaryHover: colors.blue50,
    buttonTertiaryActive: colors.blue100,
    buttonTertiaryText: colors.blue500,

    variants: {
      button: buttonVariantsColors,
    },

    // Links
    linkText: colors.blueAccent,

    // Charts
    chartLabel: colors.gray500,
    chartBG: colors.white,
    chart: chartContentColors,
  },
  borders: {
    buttonBorderRadius: '6px',
    inputBorderRadius: '6px',
    useRoundedCorners: true,
    radius300: '6px',
    border100: {
      borderColor: '#00000000 !important',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    border200: {
      borderColor: colors.gray200,
      borderStyle: 'solid',
      borderWidth: '1px',
    },
  },
  typography: {
    ...typography,
  },
  breakpoints: {
    small: 376,
    medium: 601,
    large: 901,
    xlarge: 1367,
  },
  mediaQuery: {
    small: '@media screen and (min-width: 376px)',
    medium: '@media screen and (min-width: 601px)',
    large: '@media screen and (min-width: 901px)',
    xlarge: '@media screen and (min-width: 1367px)',
  },
  grid: {
    unit: 'px',
    columns: [2, 4, 8, 12],
    gutters: [16, 16, 24, 24],
    margins: [16, 16, 24, 32],
    gaps: [16, 16, 24, 24],
    maxWidth: 1919,
  },
  elevations: elevations,
};

type ThemeWithElevations = Theme & {
  elevations?: keyof typeof elevations;
};

export const MainTheme: ThemeWithElevations = createTheme(primitives, overrides as any);
export const DarkTheme: ThemeWithElevations = createDarkTheme(primitives, overrides);

if (global.window) {
  (global.window as any).bePrintTheme = () => {
    return MainTheme;
  };
}
