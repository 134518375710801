import { useScript } from './useScript';

export function useGoogleAnalytics(measurementID: string, disabled = false) {
  // Hook Hubspot scripts but conditionally exclude the URL if this should actually be disabled
  useScript('gtag-loader', disabled ? null : `//www.googletagmanager.com/gtag/js?id=${measurementID}`, false, true);
  useScript(
    'gtag-script-body',
    null,
    false,
    false,
    '  window.dataLayer = window.dataLayer || [];\n' +
      '  function gtag(){dataLayer.push(arguments);}\n' +
      "  gtag('js', new Date());\n" +
      '\n' +
      `  gtag('config', '${measurementID}');`
  );
}
