import { Block, BlockComponentType, BlockOverrides } from 'baseui/block';
import { BeSpinner } from '@benefeature/shared/ui';
import { ReactNode, useEffect, useRef, useState } from 'react';
import toNumber from 'lodash-es/toNumber';

export type BeSpinnerMessageProps = {
  message?: ReactNode;
  containerOverrides?: BlockOverrides;
  noMarginBottom?: boolean;
  ContainerComponent?: BlockComponentType<'div'>;
  color?: string;
  spinnerSize?: string | number;
};

export const BeSpinnerMessage = ({
  message,
  containerOverrides,
  noMarginBottom,
  ContainerComponent = Block,
  color,
  spinnerSize,
}: BeSpinnerMessageProps) => {
  const containerRef = useRef(null);
  const [containerFontSize, setContainerFontSize] = useState(null);
  const [spinnerBorderWidth, setSpinnerBorderWidth] = useState(2);

  useEffect(() => {
    if (containerRef.current && window) {
      const size = window.getComputedStyle(containerRef.current, null).getPropertyValue('font-size');

      // Set the container's font size to use as a reference point for various style attributes
      setContainerFontSize(size);

      // Set the spinner's border width as a scaled value derived from the font size
      setSpinnerBorderWidth(Math.max(Math.ceil(toNumber(size.replace('px', '')) / 6), 2));
    }
  }, [containerRef, ContainerComponent]);

  return (
    <ContainerComponent
      ref={containerRef}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      gridColumnGap={containerFontSize || '16px'}
      {...(noMarginBottom ? {} : { marginBottom: containerFontSize || '16px' })}
      {...(containerOverrides ? { overrides: containerOverrides } : {})}
    >
      <BeSpinner
        {...(containerFontSize
          ? {
              $size: containerFontSize,
            }
          : {})}
        {...(spinnerBorderWidth
          ? {
              $borderWidth: `${spinnerBorderWidth}px`,
            }
          : {})}
        {...(color
          ? {
              $color: color,
            }
          : {})}
        {...(spinnerSize ? { $size: spinnerSize } : {})}
      />
      {message}
    </ContainerComponent>
  );
};
