import { ReactNode } from 'react';
import { Card, CardOverrides, CardProps, StyledThumbnail as RightStyledThumbnail } from 'baseui/card';
import { styled, useStyletron, withStyle } from 'baseui';
import { useDebugAttrs } from '../../hooks';
import { BaseWebHelpers, optionalLinkWrap } from '../../helpers';
import { MainTheme } from '../../themes';

export type BeCardProps = Omit<CardProps, 'thumbnail' | 'hasThumbnail' | 'overrides'> & {
  sticker?: ReactNode;
  cardLink?: string;
  bgColor?: string;
  elevation?: typeof MainTheme.elevations;
  onClick?: (e?: MouseEvent) => any;
  id?: string;
  isHighlighted?: boolean;
  hoverHeight?: typeof MainTheme.elevations;
  /* Must omit the following from CardProps and redeclare as optional (BaseWeb v12 TS update) */
  thumbnail?: string;
  hasThumbnail?: (a: { readonly thumbnail?: string }) => boolean;
  overrides?: CardOverrides;
};

export const BeCard = ({
  children,
  sticker,
  thumbnail,
  cardLink,
  bgColor,
  elevation,
  onClick,
  id,
  isHighlighted,
  hoverHeight,
  overrides,
  ...props
}: BeCardProps) => {
  const [section] = useDebugAttrs('BeCard');
  const [css, $theme] = useStyletron();

  const { Root: rootOverrides, ...otherOverrides } = overrides || {};

  return optionalLinkWrap(
    <Card
      {...(id ? { id: id } : {})}
      {...section('root')}
      {...props}
      overrides={{
        Root: {
          style: {
            /* Hide the x-axis overflow to prevent subtle errors (BFR-499) */
            overflowX: 'hidden',
            width: '100%',
            position: 'relative',
            ...(elevation ? $theme['elevations']?.[elevation] || $theme['elevations']?.z0 : $theme['elevations']?.z0),
            ...(bgColor ? { backgroundColor: bgColor } : {}),
            ...(cardLink || onClick
              ? {
                  cursor: 'pointer',
                }
              : {}),
            ...(cardLink || onClick || hoverHeight
              ? {
                  /* Include a subtle hover effect, include z0 to ensure transitions work */
                  ...$theme['elevations']?.['z0'],
                  ':hover': { ...$theme['elevations']?.[hoverHeight || 'z6'] },
                }
              : {}),
            ...(isHighlighted
              ? /* Would use the :target pseudoclass, but it doesn't appear to work properly in overrides (BFR-431) */
                BaseWebHelpers.expandBorderStyles({
                  borderColor: $theme.colors['yellow900'],
                  borderStyle: 'solid',
                  borderWidth: '4px',
                })
              : {}),
            ...(rootOverrides?.style || {}),
          },
          props: {
            ...(rootOverrides?.props || {}),
          },
        },
        ...otherOverrides,
      }}
    >
      {sticker && <StyledNote {...section('sticker')}>{sticker}</StyledNote>}
      {thumbnail && <LeftStyledThumbnail {...section('thumbnail')} src={thumbnail} />}
      {children}
    </Card>,
    cardLink,
    onClick,
    {
      cursor: 'pointer',
      textDecoration: 'none',
    }
  );
};

export const LeftStyledThumbnail = withStyle(RightStyledThumbnail, ({ $theme }) => ({
  float: 'left',
}));

const StyledNote = styled('span', ({ $theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
}));
